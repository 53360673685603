<template>
  <div v-if="worker.progress !== undefined">
    <div v-if="worker.progress" class="mt-3 text-caption">
      <b>Working on:</b> {{ worker.progress.name }}<br />

      <b>Started:</b> {{ formatUnix(worker.progress.start_time)
      }}<br />
      <span v-if="worker.service !== 'conversions' && worker.service !== 'uploads'">
        <b>Estimated Completion:</b>
      {{ estimateCompletion(worker.progress) }}<br />
        <span v-if="worker.progress.units == 'bytes'">
                    <b>Progress:</b> {{ worker.progress.done | prettyBytes }} of
                    {{ worker.progress.total | prettyBytes }}<br />
                  </span>
      <span v-if="worker.progress.units !== 'bytes'">
                    <b>Progress:</b> {{ worker.progress.done }} of
                    {{ worker.progress.total }} {{ worker.progress.units }}<br />
                  </span>

      <v-divider></v-divider>

      <span v-if="worker.progress.phase == 'untar'" class="mt-3 text-caption">
                      <b>Download Complete, Extracting</b>
                      <v-progress-linear
                          class="mt-3"
                          color="green"
                          buffer-value="0"
                          :value="100"
                          stream
                      ></v-progress-linear>
                    </span>

      <v-progress-linear
          class="mt-3"
          color="teal"
          buffer-value="0"
          :value="(worker.progress.done * 100) / worker.progress.total"
          stream
      ></v-progress-linear>


      <div v-if="worker.progress.phase == 'get'" class="mt-3 text-caption">
        <b>Extraction Pending</b>
        <v-progress-linear
            class="mt-3"
            color="blue"
            buffer-value="0"
            :value="0"
            stream
        ></v-progress-linear>
      </div>

        </span>

    </div>

    <span v-if="worker.service == 'conversions'">

      <v-divider></v-divider>

      <span v-if="worker.status == 'dataproduct_ingestion'" class="mt-3 text-caption">
          <b>Pipeline Complete, Ingesting</b>
          <v-progress-linear
              class="mt-3"
              color="green"
              buffer-value="0"
              :value="50"
              stream
          ></v-progress-linear>
        </span>

      <span v-if="worker.status == 'pipeline'" class="mt-3 text-caption">
          <b>Pipeline Running, Ingestion Pending</b>
          <v-progress-linear
              class="mt-3"
              color="blue"
              buffer-value="0"
              :value="0"
              stream
          ></v-progress-linear>
        </span>
    </span>

    <span v-if="worker.service == 'uploads'">

      <v-divider></v-divider>

      <span v-if="worker.status == 'registering'" class="mt-3 text-caption">
          <b>Harvest/Copy Complete, Registering</b>
          <v-progress-linear
              class="mt-3"
              color="green"
              buffer-value="0"
              :value="50"
              stream
          ></v-progress-linear>
        </span>

      <span v-if="worker.status == 'harvesting'" class="mt-3 text-caption">
          <b>Harvesting Files, Registration Pending</b>
          <v-progress-linear
              class="mt-3"
              color="blue"
              buffer-value="0"
              :value="0"
              stream
          ></v-progress-linear>
        </span>
    </span>


    <v-divider class="my-1"></v-divider>
  </div>
</template>

<script>
  export default {
    name: 'WorkerProgress',
    props: {
      worker: Object
    },
    methods: {
      formatUnix: function (value) {
        console.log(`TIMESTAMP IS SET TO ${value}`)
        if (value) {
          return this.$moment.unix(value).from()
        }
      },
      estimateCompletion: function (p) {
        let duration = Date.now() / 1000 - p.start_time
        let remain = p.total - p.done
        let rate = p.done / duration
        let time_left = remain / rate
        console.log(duration, remain, rate, time_left)
        return this.$moment().add(time_left, 'seconds').from()
      },
    }
  }
</script>
