<template>
    <div>
        <v-btn v-if="!showConfirmDelete" right icon color="error" small @click="showConfirmTemporarily"
            class="elevation-2"><v-icon>mdi-delete</v-icon></v-btn>

        <v-btn v-if="showConfirmDelete" right small @click="$emit('click')" class="elevation-2"><v-icon class="mr-3"
                color="amber darken-3">mdi-alert-circle-outline</v-icon>Click to confirm</v-btn>
    </div>

</template>

<script>
export default {
    name: 'DeleteButtonWithConfirm',
    data() {
        return {
            showConfirmDelete: false
        }
    },
    methods: {
        showConfirmTemporarily() {
            this.showConfirmDelete = true
            setTimeout(() => this.showConfirmDelete = false, 3000);
        }
    }
}
</script>