<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>
      <v-icon class="mx-2">mdi-share-all</v-icon> Data Sharing
    </v-card-title>
    <v-card-text>
      <v-combobox
        v-model="share_contacts"
        label="Enter email addresses to share dataset"
        multiple
        chips
        hint="Press tab to complete entry"
      ></v-combobox>

      <div class="text-body-2 red--text">
        <v-icon class="mx-2" color="red">mdi-alert</v-icon> Sharing will enable
        downloads for anyone with the link.
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancel"> Close </v-btn>

      <v-btn color="success" text @click="shareDownload"> Share </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    active_dl: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      share_contacts: [],
    }
  },
  computed: {},
  methods: {
    shareDownload() {
      let data = {
        contacts: this.share_contacts,
        link: `${this.$config.download}/${this.active_dl.uuid}/`,
      }

      this.$http
        .post(`${this.$config.api}/downloads/share/${this.active_dl._id}`, data)
        .then(() => {
          this.$store.dispatch('snack', `Email sent`)

          const event = {
            action: 'Download Share Email',
            details: `${this.share_contacts}`,
            route: this.$router.currentRoute.path,
            dataproduct: this.active_dl._id,
            dataset: null,
            project: null,
            worker: null,
          }

          this.$http.post(`${this.$config.api}/events/new`, event).then(() => {
            this.$emit('close-dialog')
          })

          // this.$emit('close-dialog')
          // console.log(response.data)
        })
    },

    cancel() {
      this.$emit('close-dialog')
    },
  },
  mounted() {
    // console.log('DownloadInstructions -> ', this.active_dl)
  },
}
</script>
