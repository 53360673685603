<template>
  <div>
    <v-dialog v-model="detail_dialog" v-if="conversion">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ dialog_title }}
        </v-card-title>

        <v-card-text v-if="dtype == 'log'">
          <LogViewer :name="conversion.dataset.name" :status="conversion.status"></LogViewer>
        </v-card-text>

        <v-card-text v-if="dtype == 'sample'">
          <pre>{{ conversion.samplesheet }}</pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="logfile_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="view_mode == 'inline'" class="d-flex justify-end">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn small fab color="blue lighten-2" v-on="on" @click.stop="handleClick()" class="ma-1">
            <v-icon>mdi-shape-square-rounded-plus</v-icon>
          </v-btn>
        </template>
        <span> Open this details page in a new tab </span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn small fab color="green lighten-2" v-on="on" @click.stop="copyLink()" class="ma-1">
            <v-icon>mdi-clipboard-check</v-icon>
          </v-btn>
        </template>
        <span> Copy direct link to this conversion details </span>
      </v-tooltip>

    </div>

    <div class="text-h4 font-weight-light" v-if="conversion && view_mode == 'page'">
      <v-icon large class="pb-2 ml-1">mdi-transition</v-icon>
      <router-link :to="{ name: 'Conversions' }">Conversion</router-link>
      : {{ conversion.dataset.name }}
    </div>
    <v-divider class="mb-3"></v-divider>

    <v-container fluid v-if="conversion">
      <v-row>
        <v-col cols="5">
          <v-card class="elevate-3">
            <v-container v-if="conversion.user && conversion.dataset">
              <h2>General info</h2>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Submitted</div>
                </v-col>
                <v-col>{{ conversion.createdAt | moment('YYYY-MM-DD') }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Last Updated</div>
                </v-col>
                <v-col>{{ conversion.updatedAt | moment('YYYY-MM-DD') }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Submitted By</div>
                </v-col>
                <v-col>{{ conversion.user.fullname }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Pipeline</div>
                </v-col>
                <v-col>{{ conversion.pipeline }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Pipeline Options</div>
                </v-col>
                <v-col>
                  <li v-for="(opt, idx) in conversion.options" :key="idx">
                    {{ opt }}
                  </li>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Dataset</div>
                </v-col>
                <v-col>{{ conversion.dataset.name }}
                  <v-icon v-show="!showDataset" color="info" @click="showDataset = true">
                    mdi-plus-circle-outline
                  </v-icon>
                  <v-icon v-show="showDataset" color="warnning" @click="showDataset = false">
                    mdi-minus-circle-outline
                  </v-icon>
                </v-col>
              </v-row>

              <div v-show="showDataset">
                <v-row>
                  <v-col cols="4" justify="end">
                    <div class="text-right">Total Size</div>
                  </v-col>
                  <v-col>{{ parseInt(conversion.dataset.size) | gigaBytes }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" justify="end">
                    <div class="text-right">Total Files</div>
                  </v-col>
                  <v-col>{{ conversion.dataset['files'] }}</v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" justify="end">
                    <div class="text-right">Total CBCLs</div>
                  </v-col>
                  <v-col>{{ conversion.dataset['cbcls'] }}</v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" justify="end">
                    <div class="text-right">Total Directories</div>
                  </v-col>
                  <v-col>{{ conversion.dataset['directories'] }}</v-col>
                </v-row>
              </div>
            </v-container>
          </v-card>

          <v-card class="elevate-3 my-2">
            <v-container v-if="conversion.user && conversion.dataset">
              <h2>Details</h2>

              <v-row>
                <v-col>
                  <v-btn @click.stop="showDialog('sample')" color="yellow lighten-3" class="mb-4 mr-4">
                    <v-icon class="mr-2">mdi-file-delimited</v-icon>
                    Samplesheet
                  </v-btn>

                  <v-btn @click.stop="showDialog('log')" color="teal lighten-3" class="mb-4 mr-4">
                    <v-icon class="mr-2">mdi-file-cog</v-icon>
                    Log File
                  </v-btn>

                  <v-btn :href="`/api/reports/${conversion._id}/Reports/html/`" target="_blank" color="green lighten-3"
                    class="mb-4 mr-4" v-show="conversion.status == 'completed'">
                    <v-icon class="mr-2">mdi-file-chart</v-icon>
                    Report
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="7">
          <v-card class="elevate-3 mb-4">
            <v-container>
              <h2>Status: {{ conversion.status }}</h2>

              <v-row v-if="conversion.status == 'working'">
                <v-col>
                  <v-row>
                    <v-col cols="2" justify="end">
                      <div class="text-right">Worker</div>
                    </v-col>
                    <v-col>{{ conversion.worker.name }}</v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="2" justify="end">
                      <div class="text-right">Host</div>
                    </v-col>
                    <v-col>{{ conversion.worker.host }}</v-col>
                  </v-row>

                  <div class="elevate-2 ma-1">
                    <WorkerProgess :worker="conversion.worker" />
                  </div>

                  <v-row>
                    <v-col>
                      <Confirm title="Confirm" :message="'Are you sure you want to abort this conversion process?'"
                        color="red" v-on:confirm="abortConversion()">
                        <template v-slot:label>
                          <v-btn color="red" class="mb-4 mr-4">
                            <v-icon class="mr-2">mdi-close-octagon</v-icon>
                            Abort
                          </v-btn>
                        </template>
                      </Confirm>

                      <!--                      <Confirm-->
                      <!--                          title="Confirm"-->
                      <!--                          :message="'Are you sure you want to restart this conversion process?  The current process will terminate and intermediate files will be deleted before restarting with the exact same parameters.  This should only be used if you suspect there was a temporary error that has been resolved.'"-->
                      <!--                          color="warning"-->
                      <!--                          v-on:confirm="restartConversion()"-->
                      <!--                      >-->
                      <!--                        <template v-slot:label>-->
                      <!--                          <v-btn color="warning" class="mb-4 mr-4">-->
                      <!--                            <v-icon class="mr-2">mdi-restart</v-icon>-->
                      <!--                            Restart-->
                      <!--                          </v-btn>-->
                      <!--                        </template>-->
                      <!--                      </Confirm>-->

                      <!--                      <Confirm-->
                      <!--                          title="Confirm"-->
                      <!--                          :message="'Are you sure you want to resubmit this conversion process? The current process will be terminate and any products produced will be removed.  You will then have the opportunity to modify the conversion parameters the process restarts.  Use this option if a pipeline option was incorrectly set or there is an error in the samplesheet.'"-->
                      <!--                          color="info"-->
                      <!--                          v-on:confirm="resubmitConversion()"-->
                      <!--                      >-->
                      <!--                        <template v-slot:label>-->
                      <!--                          <v-btn color="info" class="mb-4 mr-4">-->
                      <!--                            <v-icon class="mr-2">mdi-autorenew</v-icon>-->
                      <!--                            Resubmit-->
                      <!--                          </v-btn>-->
                      <!--                        </template>-->
                      <!--                      </Confirm>-->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <div v-else>
                <!--                <v-row>-->
                <!--                  <v-col cols="4" justify="end">-->
                <!--                    <div class="text-right">Submitted</div>-->
                <!--                  </v-col>-->
                <!--                  <v-col>{{ conversion.createdAt | moment('YYYY-MM-DD') }} </v-col>-->
                <!--                </v-row>-->

                <v-row>
                  <v-col cols="4" justify="end">
                    <div class="text-right">Runtime</div>
                  </v-col>
                  <v-col>{{
                    conversion.updatedAt
                    | moment('from', conversion.createdAt, true)
                  }}
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card>

          <v-card class="elevate-3 mb-4" v-if="conversion.status == 'completed'">
            <v-container>
              <h2 class="mb-2">Dataproducts</h2>
              <div class="mx-2" v-if="conversion.dataproducts.length">
                <li v-for="(dp, idx) in conversion.dataproducts" :key="idx">
                  {{ dp.name }} - {{ dp.files.length }} files
                  <span v-if="dp.qc_url !== ''">
                    <v-tooltip v-if="dp.qc_url !== ''" top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon small :href="dp.qc_url" target="_blank">
                          <v-icon color="info" small> mdi-book-check </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        MultiQC report available (opens in new tab)
                      </span>
                    </v-tooltip>
                  </span>
                </li>
              </div>
              <div v-else>
                <h3>No dataproducts registered. Check log file for errors.</h3>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import WorkerProgess from '@/components/WorkerProgress'
import LogViewer from '@/components/LogViewer'
import Confirm from '@/components/Confirm'

export default {
  name: 'ConversionDetails',
  components: { WorkerProgess, LogViewer, Confirm },
  props: {
    id: String,
  },
  data() {
    return {
      cID: null,
      view_mode: 'page',
      conversion: null,
      detail_dialog: false,
      dtype: null,
      dialog_title: '',
      showDataset: false,
    }
  },
  methods: {
    getConversion() {
      this.$http
        .get(`${this.$config.api}/conversions/one/${this.cID}`)
        .then((response) => {
          this.conversion = response.data
          console.log('Conversion: ', response.data)
          this.checkQC();
        })
    },
    abortConversion() {
      this.sendCommand('KILL')
    },
    restartConversion() {
      return
    },
    resubmitConversion() {
      return
    },
    sendCommand: function (cmd) {
      let self = this
      this.$http
        .get(
          `${this.$config.api}/workers/issue/${this.conversion.worker._id}/${cmd}`
        )
        .then((response) => {
          console.log(response.data)
          self.$store.dispatch('snack', 'Abort command sent')
        })
    },
    handleClick() {
      // this.$router.push({ name: 'RunDetails', params: { name: item.name } })
      let routeData = this.$router.resolve({
        name: 'ConversionDetails',
        params: { id: this.cID },
      })
      console.log('opening link', routeData.href)
      window.open(routeData.href, '_blank')
    },
    copyLink: function () {
      let container = this.$refs.container
      this.$copyText(
        `https://cmg.sca.iu.edu/conversions/${this.cID}`,
        container
      ).then(
        () => {
          this.$store.dispatch('snack', `URL copied to clipboard `)
        },
        function (e) {
          console.log(e)
        }
      )
    },

    showDialog(dtype) {
      this.dtype = dtype
      if (dtype == 'log') this.dialog_title = 'Logfile'
      if (dtype == 'sample') this.dialog_title = 'Samplesheet'
      this.detail_dialog = true
    },

    checkQC() {
      let self = this;
      this.conversion.dataproducts.forEach(dp => {
        self.$set(dp, 'qc_url', '');
        if (dp.conversion) {
          let qc_url = `${this.$config.api}/qc/${dp.conversion}/${dp.name}/multiqc_report.html`;
          this.$http.get(qc_url)
            .then(res => {
              console.log(res.status);
              self.$set(dp, 'qc_url', qc_url);
            })

        }
      })
    }

  },
  mounted() {
    if (this.id) {
      this.cID = this.id
      this.view_mode = 'inline'
    } else {
      this.cID = this.$route.params.id
    }

    this.getConversion()
  },
}
</script>
