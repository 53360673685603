var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":"Search","outlined":"","prepend-inner-icon":"mdi-magnify","clearable":""},on:{"input":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"mb-4 d-flex justify-space-between"},[_c('v-pagination',{staticClass:"ml-4",attrs:{"length":_vm.pages,"total-visible":"7","circle":""},on:{"input":_vm.getDatasets},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('items-count',{key:_vm.total,staticClass:"mr-4",attrs:{"total":_vm.total}})],1),_c('v-data-table',{staticClass:"elevation-4",attrs:{"items":_vm.datasets_filtered,"headers":_vm.headers,"item-key":"_id","disable-pagination":"","hide-default-footer":"","dense":"","sort-by":"takenAt","sort-desc":true,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options},on:{"current-items":_vm.updateSummary,"update:options":[function($event){_vm.options=$event},_vm.handleUpdateOptions]},scopedSlots:_vm._u([{key:"item.taken",fn:function(ref){
var item = ref.item;
return [(item.taken)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"green"}}):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(item)}}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-shape-square-rounded-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(" Open details in new tab ")])]),_c('router-link',{attrs:{"to":{ name: 'RunDetails', params: { name: item.name } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.takenAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.takenAt,'YYYY-MM-DD'))+" ")]}},{key:"item.archived",fn:function(ref){
var item = ref.item;
return [(item.archived)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e(),(!item.archived)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"gray lighten-5"}},[_vm._v(" mdi-cancel ")]):_vm._e()]}},{key:"item.validated",fn:function(ref){
var item = ref.item;
return [(item.validated)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.staged)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-lan-pending ")]):_c('v-icon',{staticClass:"mx-1",attrs:{"color":"gray lighten-5"}},[_vm._v("mdi-cancel")])]}},{key:"item.staged",fn:function(ref){
var item = ref.item;
return [(item.staged)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(!item.staged)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"gray lighten-5"}},[_vm._v("mdi-cancel")]):_vm._e()]}},{key:"item.converted",fn:function(ref){
var item = ref.item;
return [(item.converted)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(!item.converted)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"gray lighten-5"}},[_vm._v("mdi-cancel")]):_vm._e()]}},{key:"item.errored",fn:function(ref){
var item = ref.item;
return [(item.errored)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"red"}},[_vm._v("mdi-alert")]):_vm._e(),(!item.errored)?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"green lighten-5"}},[_vm._v("mdi-cancel")]):_vm._e()]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'from'))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-end":""}},[_c('file-size',{attrs:{"bytes":item.size}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }