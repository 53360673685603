var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":"Search","outlined":"","prepend-inner-icon":"mdi-magnify","clearable":""},on:{"input":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"mb-4 d-flex justify-space-between"},[_c('v-pagination',{staticClass:"ml-4",attrs:{"length":_vm.pages,"total-visible":"7","circle":""},on:{"input":_vm.getDataProducts},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('items-count',{key:_vm.total,staticClass:"mr-4",attrs:{"total":_vm.total}})],1),_c('v-data-table',{staticClass:"elevation-4",attrs:{"items":_vm.dataproducts,"headers":_vm.headers,"sort-by":"createdAt","sort-desc":true,"dense":"","hide-default-footer":"","disable-pagination":"","single-expand":true,"show-expand":false,"item-key":"_id","loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.handleUpdateOptions]},scopedSlots:_vm._u([{key:"item.method",fn:function(ref){
var item = ref.item;
return [(item.conversion)?_c('div',[_c('router-link',{staticClass:"mb-4 mr-4 grey--text text--darken-2",attrs:{"to":{
            name: 'Conversions',
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-transition")]),_vm._v(" Conversion ")],1),(item.qc_url && item.qc_url !== '')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-2",attrs:{"small":"","href":item.qc_url,"target":"_blank"}},on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-book-check ")])],1)]}}],null,true)},[_c('span',[_vm._v(" MultiQC report available (opens in new tab) ")])]):_vm._e()],1):(item.upload)?_c('div',[_c('router-link',{staticClass:"mb-4 mr-4 grey--text text--darken-2",attrs:{"to":{
            name: 'DataUploader',
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-folder-upload")]),_vm._v(" Upload ")],1),(item.qc_url && item.qc_url !== '')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-2",attrs:{"small":"","href":item.qc_url,"target":"_blank"}},on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-book-check ")])],1)]}}],null,true)},[_c('span',[_vm._v(" MultiQC report available (opens in new tab) ")])]):_vm._e()],1):_vm._e()]}},{key:"item.dataset",fn:function(ref){
          var item = ref.item;
return [(item.dataset)?_c('div',[_c('router-link',{attrs:{"to":{
            name: 'RunDetails',
            params: { name: item.dataset.name, product: item._id },
          }}},[_vm._v(" "+_vm._s(item.dataset.name)+" ")])],1):_vm._e()]}},{key:"item.project",fn:function(ref){
          var item = ref.item;
return [(item.project)?_c('div',[_c('router-link',{attrs:{"to":{
            name: 'ProjectDetails',
            params: { name: item.project.name },
          }}},[_vm._v(" "+_vm._s(item.project.name)+" ")])],1):_vm._e()]}},{key:"item.files",fn:function(ref){
          var item = ref.item;
return [(!_vm.isEmpty && item.files)?_c('div',[_vm._v(" "+_vm._s(item.files.length)+" ")]):_vm._e()]}},{key:"item.size",fn:function(ref){
          var item = ref.item;
return [(!_vm.isEmpty && item.size)?_c('v-layout',{attrs:{"justify-end":""}},[_c('file-size',{attrs:{"bytes":item.size}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }