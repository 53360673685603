var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',{staticClass:"mt-4 mx-auto"},[_c('v-card-text',{staticClass:"pt-0"},[_c('disk-usage')],1)],1)],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"my-4 mx-3 elevation-2",attrs:{"items":_vm.workers,"headers":_vm.headers,"show-expand":"","item-key":"_id","expanded":_vm.expanded,"disable-pagination":"","hide-default-footer":"","dense":"","sort-by":"host","sort-desc":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"info"}},[_vm._v(_vm._s(_vm.w_m[item.service].icon))]),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.w_m[item.service].title))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'from'))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(
                  item.status === 'working' &&
                  item.service === 'stagings' &&
                  item.progress === null
                )?_c('div',[_c('div',{staticClass:"mt-3 text-caption"},[_c('b',[_vm._v(" Requesting job on the SDA. Progress will update when the job begins ")])])]):_vm._e(),_c('WorkerProgess',{attrs:{"worker":item}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"yellow darken-2"},on:{"click":function($event){return _vm.confirmCommand(item, 'PAUSE')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-motion-pause")])],1)]}}],null,true)},[_c('span',[_vm._v("Pause Worker")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.confirmCommand(item, 'RESUME')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-motion-play")])],1)]}}],null,true)},[_c('span',[_vm._v("Resume Worker")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange"},on:{"click":function($event){return _vm.confirmCommand(item, 'KILL')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Stop Active Job")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.confirmCommand(item, 'HALT')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-exit-run")])],1)]}}],null,true)},[_c('span',[_vm._v("Shutdown Worker")])])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Issue Worker Command ")]),_c('v-card-text',[_vm._v(" Are you sure you want to issue the command "+_vm._s(_vm.cmd)+" to Worker "+_vm._s(_vm.worker.name)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.sendCommand}},[_vm._v(" Agree ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }