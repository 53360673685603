<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-chip outlined pill v-on="on">{{ gigabytes }}</v-chip>
      </template>
      {{ bytes }} bytes
    </v-tooltip>
    <v-layout justify-end>
      <div class="size-bar" :style="dynamicStyle"></div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'FileSize',
  props: {
    bytes: {
      type: Number,
      required: true,
      defualt: 0,
    },
  },
  data() {
    return {}
  },
  computed: {
    sizePercent() {
      // convert 1200 GB into bytes
      const maxSize = 1200 * 1024 * 1024 * 1024
      return (this.bytes / maxSize) * 100
    },
    dynamicStyle() {
      return `width:${this.sizePercent}%`
    },
    gigabytes() {
      if (typeof this.bytes !== 'number' || isNaN(this.bytes)) {
        throw new TypeError('Expected a number')
      }

      var marker = 1024 // Change to 1000 if required
      var decimal = 2
      // var kiloBytes = marker // One Kilobyte is 1024 bytes
      // var megaBytes = marker * marker // One MB is 1024 KB
      var gigaBytes = marker * marker * marker // One GB is 1024 MB
      // var teraBytes = marker * marker * marker * marker // One TB is 1024 GB

      // // return bytes if less than a KB
      // if(bytes < kiloBytes) return bytes + " Bytes";
      // // return KB if less than a MB
      // else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // // return MB if less than a GB
      // else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // // return GB if less than a TB
      return (this.bytes / gigaBytes).toFixed(decimal) + ' GB'
    },
  },
  mounted() {
    // console.log('Passed in bytes:', this.bytes)
  },
}
</script>

<style scoped>
.size-bar {
  height: 10px;
  background-color: #227bd4;
}
</style>