<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>
      Data Access Instructions
    </v-card-title>

    <v-card-text>
      There are multiple methods available to access CMG-SCA data. Please select
      the option that best fits your needs. Use care when downloading datasets
      outside of the IU network; large datasets can consume a significant
      portion of a home ISP's monthly data cap. Transfers within IU's network
      will likely experience better performance than external transfers.

      <v-list>
        <v-subheader>Data Access Options</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item @click="doCopyDL">
            <v-list-item-avatar> 1 </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Direct Download -
                <small>
                  Transfer will use
                  {{ active_dl.files.size | prettyBytes }} of bandwidth
                </small>
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ $config.download }}/{{ active_dl.uuid }}/
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-clipboard</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <span ref="container">
            <v-list-item @click="doCopyCommand">
              <v-list-item-avatar> 2 </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  wget -
                  <small>
                    Command-line data access tool. Transfer will use
                    {{ active_dl.files.size | prettyBytes }} of bandwidth
                  </small>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-text="getCommand"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-clipboard</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item @click="doCopyDC">
              <v-list-item-avatar> 3 </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  IU Storage -
                  <small
                    >Access data directly through IU's computing clusters</small
                  ></v-list-item-title
                >
                <v-list-item-subtitle
                  v-text="
                    `${$config.paths.download_dir}/${active_dl.uuid}/`
                  "
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-clipboard</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </span>
        </v-list-item-group>
      </v-list>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancel"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    active_dl: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getCommand: function () {
      // If changing this command,
      // don't forget to also update the link that is included with the email
      // in api/routes/downloads.js
      return `wget --recursive --continue --no-host-directories --no-parent --cut-dirs=1 -A "*.gz,*.bam,*.bai,*.json,*.txt,*.html,*.h5,*.cloupe,*tbi,*.csv" -P ${this.active_dl.name} ${this.$config.download}/${this.active_dl.uuid}`
    },
  },
  methods: {
    doCopyDC: function () {
      let container = this.$refs.container
      this.$copyText(
        `${this.$config.paths.download_dir}/${this.active_dl.uuid}/`,
        container
      ).then(
        () => {
          this.$store.dispatch(
            'snack',
            `Data path on IU Storage copied to clipboard `
          )
          // console.log('copy DC result:', e)
          // console.log('current route', this.$router.currentRoute.path)
          console.log('current product:', this.active_dl)
          // don't have easy access to dataset or project in this context
          // route should be
          const event = {
            action: 'Download Copy',
            details: 'DC Location',
            route: this.$router.currentRoute.path,
            // TODO: change all of these to just be
            // dataproduct: this.active_dl,
            // so dataproduct is assigned correctly at API layer
            // this will also require migrating existing events
            dataproduct: this.active_dl._id,
            dataset: null,
            project: null,
            worker: null,
          }
          // console.log('copy DC event:', event)

          this.$http.post(`${this.$config.api}/events/new`, event).then(() => {
            // console.log('event logging: ', response.data)
            this.$emit('close-dialog')
          })
        },
        function (e) {
          console.log(e)
        }
      )
    },
    doCopyDL: function () {
      let container = this.$refs.container
      this.$copyText(
        `${this.$config.download}/${this.active_dl.uuid}`,
        container
      ).then(
        () => {
          this.$store.dispatch('snack', `Download URL copied to clipboard `)
          // console.log('copy DL result:', e)
          const event = {
            action: 'Download Copy',
            details: 'Direct Download',
            route: this.$router.currentRoute.path,
            dataproduct: this.active_dl._id,
            dataset: null,
            project: null,
            worker: null,
          }
          // console.log('copy DC event:', event)

          this.$http.post(`${this.$config.api}/events/new`, event).then(() => {
            // console.log('event logging: ', response.data)
            this.$emit('close-dialog')
          })
        },
        function (e) {
          console.log(e)
        }
      )
    },
    doCopyCommand: function () {
      let container = this.$refs.container
      this.$copyText(this.getCommand, container).then(
        () => {
          this.$store.dispatch('snack', `get command copied to clipboard`)
          // console.log('copy command result:', e)
          const event = {
            action: 'Download Copy',
            details: 'wget command',
            route: this.$router.currentRoute.path,
            dataproduct: this.active_dl._id,
            dataset: null,
            project: null,
            worker: null,
          }
          // console.log('copy DC event:', event)

          this.$http.post(`${this.$config.api}/events/new`, event).then(() => {
            // console.log('event logging: ', response.data)
            this.$emit('close-dialog')
          })
        },
        function (e) {
          console.log(e)
        }
      )
    },

    cancel() {
      this.$emit('close-dialog')
    },
  },
  mounted() {
    // console.log('DownloadInstructions -> ', this.active_dl)
  },
}
</script>
