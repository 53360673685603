<template>
  <div>
    <v-dialog v-model="mergeDialog" max-width="450px">
      <v-card>
        <v-card-title class="headline"> Merge {{ project.name }} </v-card-title>

        <v-card-text>
          <v-autocomplete prepend-icon="mdi-flask" v-model="destinationProject" :items="otherProjects" item-text="name"
            item-value="_id" :error-messages="errors" label="Destination Project"></v-autocomplete>

          Merge {{ project.name }} with selected project?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="mergeDialog = false">
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="doMerge"> Merge </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title class="headline">
          Delete {{ project.name }}
        </v-card-title>

        <v-card-text>
          Delete the record associated with this project from the database. This
          action does not remove any files related to the project. Are you sure
          you want to remove the record for project
          {{ project.name }} ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Disagree
          </v-btn>

          <v-btn color="green darken-1" text @click="deleteItem"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="renameDialog" max-width="450px">
      <v-card>
        <v-card-title class="headline">
          Rename {{ project.name }}
        </v-card-title>

        <v-card-text>
          Any future Data Products will need to use the new project name in
          order to be added to the project automatically. Be sure to specify a
          name that does not already exist.
        </v-card-text>

        <v-card-text>
          <v-text-field prepend-icon="mdi-rename-box" v-model="newName" label="New Project Name" placeholder=""
            outlined></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="renameDialog = false">
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="renameItem"> Rename</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permissionsDialog" max-width="600px">
      <permissions-form :project="project" @close-dialog="permissionsComplete"
        @update-complete="permissionsComplete"></permissions-form>
    </v-dialog>

    <div class="text-h4 font-weight-light">
      <v-icon large class="pb-2 ml-1">{{ icon }}</v-icon>
      <router-link :to="{ name: 'Projects' }">Project</router-link>
      : {{ projectName }}
    </div>
    <v-divider class="mb-3"></v-divider>

    <v-alert dense border="left" type="warning" v-if="no_access">
      You do not have access to this project's data products. If you believe
      this message is in error, please contact
      <a class="alert-link" href="mailto:hongao@iu.edu">hongao@iu.edu</a>.
    </v-alert>

    <v-container>
      <v-row>
        <v-col>
          <v-container v-if="project">
            <h2>Associated Data Products</h2>
            <v-row>
              <v-col v-if="project.dataproducts.length">
                <data-product-list :dataproducts="project.dataproducts" :headers="computedHeaders"
                  :expandable="expandable" @start-poll="startPoll" class="mt-4"></data-product-list>
              </v-col>

              <v-col v-if="!project.dataproducts.length">
                No data products available
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card v-if="project" class="elevate-3">
            <v-container>
              <h2>General info</h2>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Last updated</div>
                </v-col>
                <v-col>{{ project['updatedAt'] | moment('YYYY-MM-DD') }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Registered on</div>
                </v-col>
                <v-col>{{ project['createdAt'] | moment('YYYY-MM-DD') }}</v-col>
              </v-row>

              <v-row v-if="project.size">
                <v-col cols="4" justify="end">
                  <div class="text-right">Total Size</div>
                </v-col>
                <v-col>{{ parseInt(project.size) | gigaBytes }} </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Description</div>
                </v-col>
                <v-col>
                  <v-textarea v-model="project.description" @input="updateProject" placeholder="Add a description...">
                    {{ project['description'] }}
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card v-if="$store.getters.hasRole('admin') && !no_access" class="elevate-3 mb-4">
            <v-container>
              <h2>Access Permissions</h2>

              <v-row>
                <v-col>
                  <v-chip small dense color="secondary" v-for="u in project.users" :key="u._id">
                    {{ u.fullname }}
                  </v-chip>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <!-- <v-treeview
                    v-model="tree"
                    :open="open"
                    :items="project.groups"
                    activatable
                    item-key="name"
                    open-on-click
                  >
                    <template v-slot:prepend="{ item, open }">
                      <v-icon v-if="!item.file">
                        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                      </v-icon>
                      <v-icon v-else>
                        {{ files[item.file] }}
                      </v-icon>
                    </template>
                  </v-treeview> -->
                  <!-- <v-chip
                    small
                    color="primary"
                    v-for="g in project.groups"
                    :key="g._id"
                  >
                    {{ g.name }}
                  </v-chip> -->
                  <group-members v-for="g in project.groups" :key="g._id" :group="g">
                  </group-members>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <permissions-button @permissions-button="permissionsDialog = true"></permissions-button>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="$store.getters.hasRole('admin') && !no_access">
      <v-row>
        <v-col>
          <v-container>
            <h2>Maintenance Actions</h2>
            <v-row>
              <v-col>
                <v-btn class="mb-4 mr-4" @click.stop="getProjects">
                  <v-icon class="mr-2" color="orange lighten-2">
                    mdi-lock-open-check
                  </v-icon>
                  Merge Project
                </v-btn>

                <v-btn class="mb-4 mr-4" @click.stop="renameDialog = true">
                  <v-icon class="mr-2" color="orange lighten-2">
                    mdi-rename-box
                  </v-icon>
                  Rename Project
                </v-btn>

                <v-btn class="mb-4 mr-4" @click.stop="toggleBrowser">
                  <v-icon class="mr-2" color="orange lighten-2">
                    mdi-chart-timeline
                  </v-icon>
                  {{ project.browser ? 'Disable Genome Browser' : 'Enable Genome Browser' }}
                </v-btn>


                <!--                <v-icon class="mr-2" color="red darken-2">mdi-delete</v-icon>-->
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PermissionsButton from '@/components/PermissionsButton'
import PermissionsForm from '@/components/PermissionsForm'
import DataProductList from '@/components/DataProductList'
import GroupMembers from '@/components/GroupMembers'

import debounce from 'debounce'

export default {
  name: 'ProjectsDetails',

  // FYI: layouts are defined in router/index.js
  layout: 'datatables',
  components: {
    PermissionsButton,
    PermissionsForm,
    DataProductList,
    GroupMembers,
  },
  computed: {
    expired() {
      // 30 days ago
      let timestamp = new Date().getTime() - 30 * 24 * 60 * 60 * 1000
      if (this.project) {
        if (this.project['createdAt'] < timestamp) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    computedHeaders() {
      return this.headers.filter(h => {
        if (h.value !== 'explore') return true;
        return this.project.browser ? true : false;
      })
    }

  },
  data() {
    return {
      projectName: '',
      // aka navItem.action
      icon: 'mdi-flask',
      project: false,
      dataproducts: false,
      deleteDialog: false,
      active_item: '',

      mergeDialog: false,
      otherProjects: false,
      destinationProject: false,
      errors: [],

      renameDialog: false,
      newName: '',

      permissionsDialog: false,
      // tree: [],
      // open: ['members'],

      expandable: false,
      no_access: false,
      headers: [
        // {text: 'Status', value:'location'},
        // { value: 'data-table-expand', text: '' },
        // { text: 'Data Product', value: 'name' },
        { text: 'Sequencing Run', value: 'dataset.name' },
        { text: 'Stage', value: 'stage' },
        { text: 'Download', value: 'download' },
        { text: 'Email Instructions', value: 'email' },
        { text: 'Explore', value: 'explore' },
        { text: 'Files', value: 'files' },
        // { text: 'Groups', value: 'groups' },
        // { text: 'Users', value: 'users' },
        // { text: 'Edit', value: 'actions' },
        { text: 'Method', value: 'method' },
        { text: 'Type', value: 'file_type' },
        { text: 'Size', value: 'size' },
      ],
    }
  },
  mounted() {
    // console.log('Route: ', this.$route)
    this.projectName = this.$route.params.name
    this.getProject()
  },

  methods: {
    getProject() {
      this.$http
        .get(`${this.$config.api}/projects/${this.projectName}`)
        .then((response) => {
          this.project = response.data
          console.log('getProject result: ', response.data)
          // this.getDataProducts()
        })

        .catch((response) => {
          console.log('getProject error: ', response)
          this.no_access = true
        })
    },
    checkStatus() {
      // console.log('Poll for dataproduct updates')
      this.$http
        .get(`${this.$config.api}/projects/${this.projectName}`)
        .then((response) => {
          this.project = response.data
          // console.log('checkStatus result: ', response.data)
          let working = false
          this.project.dataproducts.forEach((dp) => {
            if (dp.requested) {
              working = true
            }
          })
          if (!working) {
            // console.log('Stopping poll for dataproduct updates')
            clearInterval(this.interval)
          }
        })

        .catch((response) => {
          console.log('checkStatus error: ', response)
        })
    },
    startPoll() {
      // console.log('Start polling for updates')
      this.interval = setInterval(
        function () {
          this.checkStatus()
        }.bind(this),
        5000
      )
    },

    getProjects() {
      this.$http
        .get(`${this.$config.api}/projects/all`)
        .then((response) => {
          this.projects = response.data
          // remove currently selected / visible project from list
          // indexOf doesn't work
          // let index = this.projects.indexOf(this.project)
          // console.log('found existing project at index:', index)
          this.otherProjects = []
          this.projects.forEach((item) => {
            if (item._id !== this.project._id) {
              this.otherProjects.push(item)
            } else {
              // console.log('skipping current project', item)
            }
          })
          // console.log('total projects', this.projects.length)
          // console.log('other projects', this.otherProjects.length)
          // console.log(response.data)
          this.mergeDialog = true
        })
        .catch((response) => console.log('getProjects error: ', response))
    },

    doMerge() {
      // destinationProject is already just the _id value
      let url = `${this.$config.api}/projects/merge/${this.project._id}/${this.destinationProject}`
      console.log('Sending to: ', url)
      this.$http
        .get(url)
        .then((response) => {
          this.mergeDialog = false
          console.log('Merge result:', response.data)
          this.projectName = response.data.destination.name
          this.getProject()
          // update address bar
          // this.$router.push(`/projects/${response.data.destination.name}`)
          this.$router.push({
            name: 'ProjectDetails',
            params: { name: response.data.destination.name },
          })
        })
        .catch((response) => console.log('mergeProject error: ', response))
    },

    toggleBrowser() {
      let self = this;
      let browser = this.project.browser ? false : true;
      this.$http.post(`${this.$config.api}/projects/toggleBrowser`, {
        _id: this.project._id,
        browser: browser
      }).then((response) => {
        self.project['browser'] = response.data.browser;
        let msg = browser ? 'Genome browser enabled' : 'Genome browser disabled'
        this.$store.dispatch('snack', msg)
      }).catch((err) => console.log(err))
    },

    updateProject: debounce(function () {
      // console.log('Project Description updated', this.project.description)
      this.$http
        .put(`${this.$config.api}/projects/update`, this.project)
        .then((response) => {
          // this.project = response.data
          console.log('updateProject result: ', response.data)
        })
        .catch((response) => console.log('updateProject error: ', response))
    }, 500),

    permissionsComplete() {
      this.getProject()
      this.permissionsDialog = false
    },
    deleteItem() {
      this.deleteDialog = false
      let item = this.active_item
      this.$http
        .delete(`${this.$config.api}/projects/${item._id}`)
        .then((response) => {
          this.$store.dispatch('snack', `Project.deleted`)
          item.taken = null
          this.projects = this.projects.filter((ds) => {
            return ds !== item._id
          })

          console.log(response.data)
        })
    },
    renameItem() {
      this.renameDialog = false
      this.project.name = this.newName
      this.$http
        // .put(`${this.$config.api}/projects/update`, this.project)
        .put(`${this.$config.api}/projects/`, this.project)
        .then((response) => {
          console.log('renameProject result: ', response.data)
          // redirect to the new project name
          this.$router.push({
            name: 'ProjectDetails',
            params: { name: this.project.name },
          })
        })
        .catch((response) => console.log('renameProject error: ', response))
    },
  },
}
</script>

<style scoped>
.alert-link {
  color: white;
}
</style>
