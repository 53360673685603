<template>
  <div>
    <highcharts :options="usersChartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
  name: 'UsersChart',
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      items: [],
      pointStart: null,
      usersChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          // did not seem to have any effect
          // displayErrors: true,
          zoomType: 'xy',
        },
        title: {
          text: 'Registered Users',
        },
        xAxis: {
          type: 'datetime',
        },
        series: [
          {
            name: 'Number of Users',
            // colorByPoint: true,
            type: 'line',
            pointInterval: 24 * 3600 * 1000, // one day
            data: [],
          },
        ],
      },
    }
  },

  computed: {},

  methods: {
    getItems() {
      this.$http.get(`${this.$config.api}/users/all`).then((response) => {
        this.items = response.data
        // console.log('Stats -> Items', this.items)
        this.usersChartOptions.series[0].data = this.usersPerDay()
        this.usersChartOptions.series[0].pointStart = this.pointStart
      })
    },
    usersPerDay() {
      let lookup = {}
      let day = ''
      let ts = 0
      for (var item of this.items) {
        // console.log('Checking item', item)
        day = item.createDate.substring(0, 10)
        ts = Date.parse(day)
        // console.log('Timestamp: ', ts)
        if (Object.prototype.hasOwnProperty.call(lookup, ts)) {
          lookup[ts] += 1
        } else {
          lookup[ts] = 1
        }
      }
      // console.log('Lookup', lookup)

      let dayInMS = 24 * 3600 * 1000
      let series = []
      // these should be in milliseconds
      let keys = Object.keys(lookup).sort()
      // get rid of the first one -- not an accurate created at
      keys = keys.slice(1)
      // console.log('lookup keys', keys)
      this.pointStart = Number(keys[0])

      let dates = this.range(keys[0], keys[keys.length - 1], dayInMS)
      // console.log('dates range', dates)

      // keep track of a running total
      let tally = 0
      for (ts of dates) {
        // console.log('checking for', ts)
        if (Object.prototype.hasOwnProperty.call(lookup, ts)) {
          tally += lookup[ts]
        }
        series.push(tally)
      }
      // console.log('Users Chart Series', series)
      return series
    },
    range(start = 0, end = undefined, step = 1) {
      // console.log('range called with', start, end, step)
      start = parseInt(start)
      end = parseInt(end)
      step = parseInt(step)

      let result = [start]
      while (start < end) {
        start += step
        if (start <= end) {
          result.push(start)
        }
      }
      return result
    },
  },
  mounted() {
    this.getItems()
  },
}
</script>

<style></style>
