<template>
  <v-card class="mx-auto" max-width="500">
    <v-list shaped>
      <p v-if="!files.length" class="mx-2">No matching files</p>
      <v-list-item-group v-if="files" v-model="selected" multiple>
        <template v-for="(item, i) in files">
          <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :value="item"
            active-class="light-blue--text text--accent-4"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="light-blue accent-4"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'FileSelector',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    // typeName: {
    //   type: String,
    //   default: '',
    // },
  },

  computed: {},

  data() {
    return {
      // items: ['Dog Photos', 'Cat Photos', '', 'Potatoes', 'Carrots'],
      // TODO: select all by default?
      // selected: [...files],
      selected: [],
    }
  },

  watch: {
    files: {
      immediate: true,
      deep: true,
      handler(newValue) {

        this.selected = [...newValue]
        this.$emit('updateSelected', this.selected)
        console.log('files changed', newValue)
        console.log('Selected:', this.selected)
      },
    },
    selected() {
      console.log('Selected:', this.selected)
      this.$emit('updateSelected', this.selected)
    },
  },
}
</script>
