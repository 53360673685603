var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',[_c('highcharts',{attrs:{"options":_vm.pieChartOptions}})],1),_c('v-col',[_c('highcharts',{attrs:{"options":_vm.requestsChartOptions}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('disk-usage')],1),_c('v-col',{attrs:{"cols":"6"}},[_c('users-chart')],1)],1)],1),_c('items-count',{attrs:{"items":_vm.selected}}),_c('v-data-table',{staticClass:"elevation-4",attrs:{"items":_vm.events,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","sort-by":"updatedAt","sort-desc":true,"item-key":"_id","search":_vm.search},on:{"current-items":_vm.updateSelected},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":"Search","outlined":"","clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.cleanAction(item.action))+" ")]}},{key:"item.route",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: item.route }}},[_vm._v(" "+_vm._s(item.route)+" ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [(!_vm.isEmpty && item.size)?_c('v-layout',{attrs:{"justify-end":""}},[_c('file-size',{attrs:{"bytes":item.size}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }