<template>
  <div>
    <!-- old approach does not handle pagination
         this component is used many places that shouldn't require pagination
    <data-product-list
      v-if="dataproducts.length"
      :dataproducts="dataproducts"
      :headers="headers"
      :expandable="false"
      :searchable="true"
      :showcounts="true"
    ></data-product-list> -->

    <v-text-field
      v-model="search"
      label="Search"
      @input="handleSearch"
      outlined
      prepend-inner-icon="mdi-magnify"
      clearable
      class="mx-4 mt-4"
    ></v-text-field>

    <div class="mb-4 d-flex justify-space-between">
      <v-pagination
        v-model="page"
        :length="pages"
        total-visible="7"
        circle
        @input="getDataProducts"
        class="ml-4"
      ></v-pagination>
      <items-count :total="total" :key="total" class="mr-4"></items-count>
    </div>

    <v-data-table
      :items="dataproducts"
      :headers="headers"
      sort-by="createdAt"
      :sort-desc="true"
      dense
      hide-default-footer
      disable-pagination
      :single-expand="true"
      :show-expand="false"
      item-key="_id"
      class="elevation-4"
      :loading="loading"
      :server-items-length="total"
      :options.sync="options"
      @update:options="handleUpdateOptions"
    >
      <template v-slot:item.method="{ item }">
        <div v-if="item.conversion">
          <router-link
            :to="{
              name: 'Conversions',
            }"
            class="mb-4 mr-4 grey--text text--darken-2"
          >
            <v-icon small class="mr-2">mdi-transition</v-icon>
            Conversion
          </router-link>
          <v-tooltip v-if="item.qc_url && item.qc_url !== ''" top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="my-2"
                v-on="on"
                small
                :href="item.qc_url"
                target="_blank"
              >
                <v-icon color="info"> mdi-book-check </v-icon>
              </v-btn>
            </template>
            <span> MultiQC report available (opens in new tab) </span>
          </v-tooltip>
        </div>
        <div v-else-if="item.upload">
          <router-link
            :to="{
              name: 'DataUploader',
            }"
            class="mb-4 mr-4 grey--text text--darken-2"
          >
            <v-icon small class="mr-2">mdi-folder-upload</v-icon>
            Upload
          </router-link>
          <v-tooltip v-if="item.qc_url && item.qc_url !== ''" top>
            <template v-slot:activator="{ on }">
              <v-btn
                  class="my-2"
                  v-on="on"
                  small
                  :href="item.qc_url"
                  target="_blank"
              >
                <v-icon color="info"> mdi-book-check </v-icon>
              </v-btn>
            </template>
            <span> MultiQC report available (opens in new tab) </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.dataset="{ item }">
        <div v-if="item.dataset">
          <router-link
            :to="{
              name: 'RunDetails',
              params: { name: item.dataset.name, product: item._id },
            }"
          >
            {{ item.dataset.name }}
          </router-link>
        </div>
      </template>

      <template v-slot:item.project="{ item }">
        <div v-if="item.project">
          <router-link
            :to="{
              name: 'ProjectDetails',
              params: { name: item.project.name },
            }"
          >
            {{ item.project.name }}
          </router-link>
        </div>
      </template>

      <template v-slot:item.files="{ item }">
        <div v-if="!isEmpty && item.files">
          {{ item.files.length }}
        </div>
      </template>

      <template v-slot:item.size="{ item }">
        <v-layout v-if="!isEmpty && item.size" justify-end>
          <file-size :bytes="item.size"></file-size>
        </v-layout>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FileSize from '@/components/FileSize'
import ItemsCount from '@/components/ItemsCount'

import debounce from 'debounce'

// heavy over lap with:
// import DataProductList from '@/components/DataProductList'
// but pagination is different enough

export default {
  name: 'DataProducts',
  components: {
    FileSize,
    ItemsCount,

    // DataProductList,
  },
  data() {
    return {
      dataproducts: [],
      headers: [
        { text: '', value: 'details' },
        { text: 'Data Product', value: 'name' },
        { text: 'Files', value: 'files' },
        { text: 'Source Sequencing Run', value: 'dataset' },
        // { text: 'Groups', value: 'groups' },
        // { text: 'Users', value: 'users' },
        // { text: 'Edit', value: 'actions' },
        { text: 'Project', value: 'project' },
        { text: 'Method', value: 'method' },
        { text: 'Type', value: 'file_type' },
        { text: 'Size', value: 'size' },
      ],
      search: '',
      total: -1,
      page: 1,
      pages: -1,
      limit: 20,
      loading: false,
      options: {},
    }
  },

  computed: {
    isEmpty() {
      if (
        this.dataproducts.length === 0 ||
        (this.dataproducts.length === 1 &&
          this.dataproducts[0].name === 'No data products available')
      ) {
        return true
      } else {
        return false
      }
    },
  },

  methods: {
    getDataProducts: debounce(function () {
      this.loading = true
      this.dataproducts = []
      this.$http
        .get(`${this.$config.api}/dataproducts/search`, {
          params: {
            page: this.page,
            limit: this.limit,
            filter: this.search,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] ? -1 : 1,
          },
        })
        .then((response) => {
          this.dataproducts = response.data.dataproducts
          this.total = parseInt(response.data.total, 10)
          this.page = response.data.page
          this.pages = response.data.pages
          console.log('Data products response from API:', response.data)
          this.checkQC()
        })
        .finally(() => {
          this.loading = false
        })
    }, 250),
    handleSearch() {
      // if we're starting a search, make sure the page gets reset to 1
      // otherwise results may not appear
      this.page = 1
      this.options.sortBy = ['createdAt']
      this.options.sortDesc = [true]
      this.getDataProducts()
    },
    handleUpdateOptions() {
      // sort options changed, display results from page 1
      this.page = 1
      this.getDataProducts()
    },
    // TODO: way to abstract into a separate component?
    // duplicated in ui/src/components/DataProductList.vue
    checkQC() {
      this.dataproducts.forEach((dp) => {
        this.$set(dp, 'qc_url', '')
        console.log(dp.upload, dp.file_type);
        if (dp.conversion || (dp.upload && dp.file_type == 'fastq')) {
          let id = dp.conversion;
          if(id == undefined) id = dp.upload;
          // console.log(`Checking on dp ${dp.conversion}, ${dp.name}`);
          let qc_url = `${this.$config.api}/qc/${id}/${dp.name}/multiqc_report.html`
          this.$http.head(qc_url).then((res) => {
            console.log(res.status)
            this.$set(dp, 'qc_url', qc_url)
          })
        }
      })
    },
  },
  mounted() {
    this.getDataProducts()
  },
}
</script>
