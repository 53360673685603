<template>
  <div>
    <v-dialog v-model="deleteDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Delete {{ dataproduct.name }}
        </v-card-title>

        <v-card-text class="mt-4">
          Are you sure you want to delete this data product?
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteDialog = false"> Cancel </v-btn>

          <v-btn color="primary" @click="deleteProduct"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="editGenomeDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Genome Details for {{ dataproduct.name }}
        </v-card-title>

        <v-card-text class="mt-4">
          <v-form ref="form" lazy-validation>
            <genome-selector @genomeSelected="handleGenome"></genome-selector>
            <v-btn
              :disabled="!genomeDetailsFormIsValid"
              color="success"
              class="mr-4"
              @click="editDetails"
            >
              Submit
            </v-btn>

            <v-btn color="error" class="mr-4" @click="editGenomeDialog = false">
              Cancel
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-tabs v-model="tab" background-color="transparent">
      <v-tab v-for="item in items" :key="item" :href="'#' + item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="Files" value="Files">
        <v-card flat>
          <v-card-text>
            <ul>
              <li v-for="(f, i) in dataproduct.files" :key="i">
                {{ f.path.split('/').pop() }}: {{ f.size | prettyBytes }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="type === 'conversion'" key="Conversion Details" value="Conversion Details">
        <v-card flat>
          <v-card-text>
            <v-tooltip bottom v-if="dataproduct.conversion.status === 'completed'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :href="`/api/reports/${dataproduct.conversion._id}/Reports/html/`"
                  target="_blank">
                  <v-icon color="success">mdi-file-chart</v-icon>
                  Conversion Report
                </v-btn>
              </template>
              <span>View Conversion Report (opens in new tab)</span>
            </v-tooltip>

            <p>
              Created: {{ dataproduct.conversion.createdAt | moment('from') }}
            </p>
            <p>
              Updated: {{ dataproduct.conversion.updatedAt | moment('from') }}
            </p>

            <p>Command Line Options:</p>
            <pre class="ma-4" color="black" dark>
                      {{ dataproduct.conversion.options }}
                      </pre>
            <p>Samplesheet:</p>
            <pre class="ma-4" color="black" dark>
                      {{ dataproduct.conversion.samplesheet }}
                        </pre>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item class="logtab" v-if="type === 'conversion'" key="Conversion Log" value="Conversion Log">
        <!--        <v-card flat>-->
        <!--          <v-card-text>-->
        <LogViewer :name="dataproduct.dataset.name" :status="dataproduct.conversion.status"></LogViewer>
        <!--          </v-card-text>-->
        <!--        </v-card>-->
      </v-tab-item>

      <v-tab-item v-if="type === 'upload'" key="Upload Details" value="Upload Details">
        <v-card flat>
          <v-card-text>
            <p>{{ dataproduct.upload.notes }}</p>
            <p>Source path: {{ dataproduct.upload.path }}</p>
            <p>Created: {{ dataproduct.upload.createdAt | moment('from') }}</p>
            <p>Status: {{ dataproduct.upload.status }}</p>
            <p>Updated: {{ dataproduct.upload.updatedAt | moment('from') }}</p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="type === 'upload' || type === 'conversion'" key="Actions" value="Actions">
        <div class="d-flex">
          <v-card flat v-if="dataproduct.visible === true">
            <v-card-text> Delete this data product? </v-card-text>
            <v-btn color="error" class="ma-4" @click="deleteDialog = true">
              <v-icon small dark> mdi-delete </v-icon>
              Delete
            </v-btn>
          </v-card>

          <v-card flat v-if="dataproduct.visible === true">
            <v-card-text> Disable data product archival? </v-card-text>

            <v-checkbox v-model="disableArchive" label="Disable archive" @change="editDetails()"
              class="shrink ml-2 my-0"></v-checkbox>
            <v-card-text> If checked, the data product will *not* be archived to the SDA </v-card-text>
          </v-card>

          <v-card flat v-if="dataproduct.visible === false">
            <v-card-text> Restore this data product? </v-card-text>
            <v-btn color="error" class="ma-4" @click="undeleteProduct()">
              <v-icon small dark> mdi-restore </v-icon>
              Restore
            </v-btn>
          </v-card>
        </div>
      </v-tab-item>

      <v-tab-item key="Genome Details" value="Genome Details">
        <v-card flat>
          <v-card-text>
            <div v-if="!editGenomeDialog">
              <p>Genome Type: {{ dataproduct.genomeType }}</p>
              <p>Genome Value: {{ dataproduct.genomeValue }}</p>
              <v-btn color="info" class="ma-4" @click="editGenomeDialog = true">
                <v-icon small dark> mdi-pencil </v-icon>
                Edit
              </v-btn>
            </div>

            <div v-if="editGenomeDialog" class="genome-details-container">
              <v-form ref="form" lazy-validation>
                <genome-selector @genomeSelected="handleGenome"></genome-selector>
                <v-btn :disabled="!genomeDetailsFormIsValid" color="success" class="mr-4" @click="editDetails">
                  Submit
                </v-btn>

                <v-btn color="error" class="mr-4" @click="editGenomeDialog = false">
                  Cancel
                </v-btn>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="debug" key="Debug" value="Debug">
        <v-card flat>
          <v-card-text>
            <ul>
              <li v-for="field in Object.keys(dataproduct)" :key="field">
                {{ field }}: {{ dataproduct[field] }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LogViewer from '@/components/LogViewer'
import GenomeSelector from './GenomeSelector.vue'

export default {
  name: 'DataProductDetails',
  components: { LogViewer, GenomeSelector },
  props: {
    dataproduct: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      tab: 'Files',
      items: [],
      // logfile: '',
      type: '',
      debug: false,
      deleteDialog: false,
      disableArchive: false,
      editGenomeDialog: false,
      genomeDetails: {
        genomeType: '',
        genomeValue: '',
      },
    }
  },
  computed: {
    genomeDetailsFormIsValid: function () {
      return this.genomeDetails.genomeValue && this.genomeDetails.genomeType
    },
  },
  methods: {
    checkProductType() {
      if (
        Object.prototype.hasOwnProperty.call(this.dataproduct, 'conversion')
      ) {
        this.type = 'conversion'
        this.items = [
          'Files',
          'Conversion Details',
          'Conversion Log',
          'Actions',
          'Genome Details',
        ]
        // this.loadLog()
      } else if (
        Object.prototype.hasOwnProperty.call(this.dataproduct, 'upload')
      ) {
        this.type = 'upload'
        this.items = ['Files', 'Upload Details', 'Actions', 'Genome Details']
      } else {
        this.type = ''
        this.items = ['Files', 'Genome Details']
      }
    },
    // loadLog() {
    //   // console.log(
    //   //   'DataProductDetails -> loadLog -> name:',
    //   //   this.dataproduct.dataset.name
    //   // )
    //   this.$http
    //     .get(
    //       `${this.$config.api}/runlogs/convert_${this.dataproduct.dataset.name}.log`
    //     )
    //     .then((response) => {
    //       // console.log(
    //       //   'DataProductDetails -> loadLog -> Results:',
    //       //   response.data
    //       // )
    //       this.logfile = response.data
    //       // this.logfile_dialog = true
    //     })
    // },
    deleteProduct() {
      this.deleteDialog = false
      console.log('deleteProduct called')
      let data = {
        _id: this.dataproduct._id,
        // groups: this.chosen_groups,
        // users: this.chosen_users,
      }

      this.$http.post(`${this.$config.api}/dataproducts/delete`, data).then(
        (res) => {
          console.log(res.data)
          // TODO: emit delete so parent can update lists
          // this.getDataProducts()
          this.$emit('reload-product')

          this.$store.dispatch('snack', `Data product deleted`)
          // this.chosen_groups = []
          // this.chosen_users = []
        },
        (err) => {
          console.log('Error contacting API')
          console.dir(err)
        }
      )
    },
    undeleteProduct() {
      console.log('undeleteProduct called')
      let data = {
        _id: this.dataproduct._id,
        // groups: this.chosen_groups,
        // users: this.chosen_users,
      }

      this.$http.post(`${this.$config.api}/dataproducts/undelete`, data).then(
        (res) => {
          console.log(res.data)
          // can emit the same thing as delete here...
          // just want to signal refresh
          this.$emit('reload-product')

          this.$store.dispatch('snack', `Data product restored`)
          // this.chosen_groups = []
          // this.chosen_users = []
        },
        (err) => {
          console.log('Error contacting API')
          console.dir(err)
        }
      )
    },
    handleGenome(genomeType, genomeValue) {
      console.log('Form received - setting values', genomeType, genomeValue)
      this.genomeDetails.genomeType = genomeType
      this.genomeDetails.genomeValue = genomeValue
    },
    editDetails() {
      this.editGenomeDialog = false

      let data = {
        _id: this.dataproduct._id,
        genomeType: this.genomeDetails.genomeType,
        genomeValue: this.genomeDetails.genomeValue,
        disableArchive: this.disableArchive,
      }

      this.$http
        .patch(`${this.$config.api}/dataproducts/${this.dataproduct._id}`, data)
        .then(() => {
          this.$store.dispatch('snack', `Data product details updated`)
          // can emit the same thing as delete here...
          // just want to signal refresh
          this.$emit('reload-product')
        })
        .catch((err) => {
          console.log('Error contacting API')
          console.dir(err)
        })
    },
  },
  mounted() {
    this.checkProductType()
  },
  watch: {
    dataproduct: function () {
      // console.log('dataproduct changed', newVal)
      this.checkProductType()
    },
  },
}
</script>

<style>
.logtab {
  max-width: 100%;
  width: 100%;
}

.genome-details-container {
  max-width: 360px;
}
</style>
