<template>
  <div>
    <v-autocomplete
      :items="Object.keys(options)"
      v-model="selectedType"
      prepend-icon="mdi-dna"
      label="Genome Type"
    ></v-autocomplete>
    <div v-if="selectedType">
      <v-autocomplete
        :items="options[selectedType].genomes"
        prepend-icon="mdi-dna"
        v-model="selectedGenome"
        @input="updateGenome()"
        label="Genome"
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
import GenomeOptions from '@/configs/genomes'

export default {
  name: 'GenomeSelector',
  components: {},
  props: {
    type: {
      type: String,
      default: '',
    },
    genome: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedType: '',
      selectedGenome: '',
      options: GenomeOptions,
    }
  },
  methods: {
    updateGenome() {
      this.$emit('genomeSelected', this.selectedType, this.selectedGenome)
    },
  },
  mounted() {
    this.selectedType = this.type
    this.selectedGenome = this.genome
  },
}
</script>
