<template>
  <div v-if="session">
    <GenomeBrowser :session="session" :tab="true"></GenomeBrowser>
  </div>
</template>

<script>
import GenomeBrowser from '@/components/GenomeBrowser';

export default {
  name: 'SessionViewer',
  components: { GenomeBrowser },
  data() {
    return {
      session: null
    }
  },
  methods: {
    getSession() {
      let self = this;
      this.$http
          .get(`${this.$config.api}/sessions/${self.$route.params.id}`)
          .then((response) => {
            this.session = response.data
            console.log(response.data)
          })
    },
  },
  mounted() {
    this.getSession();
  }
}
</script>
