<template>
  <v-data-table
    :items="cleans"
    :headers="headers"
    class="elevation-4"
    disable-pagination
    hide-default-footer
    sort-by="updatedAt"
    :sort-desc="true"
    :single-expand="true"
    item-key="_id"
    show-expand
  >
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | moment('YYYY-MM-DD') }}
    </template>

    <template v-slot:item.updatedAt="{ item }">
      {{ item.updatedAt | moment('YYYY-MM-DD') }}
    </template>

    <template v-slot:item.datasets="{ item }">
      {{ item.datasets.length }}
    </template>

    <template v-slot:item.dataproducts="{ item }">
      {{ item.dataproducts.length }}
    </template>

    <template v-slot:item.tmp_dirs="{ item }">
      {{ item.tmp_dirs.length }}
    </template>

    <template v-slot:item.size="{ item }">
      <v-layout justify-end>
        <file-size :bytes="item.size"></file-size>
      </v-layout>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div v-if="item.datasets.length">
          <h3>Sequencing Runs</h3>
          <li v-for="(f, i) in item.datasets" :key="i">
            {{ f.name }}
          </li>
        </div>
        <div v-if="item.dataproducts.length">
          <h3>Dataproducts</h3>
          <li v-for="(f, i) in item.dataproducts" :key="i">
            {{ f }}
          </li>
        </div>
        <div v-if="item.tmp_dirs.length">
          <h3>Temp Directories</h3>
          <li v-for="(f, i) in item.tmp_dirs" :key="i">
            {{ f }}
          </li>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import FileSize from '@/components/FileSize'

export default {
  name: 'Clean',
  components: { FileSize },
  data() {
    return {
      cleans: [],
      headers: [
        { value: 'createdAt', text: 'Date' },
        { value: 'updatedAt', text: 'Last Updated' },
        { value: 'status', text: 'Status' },
        { value: 'datasets', text: 'Sequencing Runs' },
        { value: 'dataproducts', text: 'Dataproducts' },
        { value: 'tmp_dirs', text: 'Temp Dirs' },
        { value: 'size', text: 'Size' },
      ],
    }
  },
  methods: {
    getCleans() {
      this.$http.get(`${this.$config.api}/clean/all`).then((response) => {
        this.cleans = response.data
        console.log(response.data)
      })
    },
  },
  mounted() {
    this.getCleans()
  },
}
</script>
