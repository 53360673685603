<template>
  <div>
    <div class="title font-weight-light mb-2">Storage Usage</div>

    <v-container>
      <v-row>
        <v-col cols="6" class="pa-0 ma-0 flex-grow-0">
          <div class="d-flex pa-1 ma-1" v-for="(item, i) in stat_items" :key="i">
            <span class="d-inline-block" style="min-width: 24px">
              <v-icon v-text="item.icon"></v-icon>
            </span>
            <span class="ml-2"> {{ item.title }}: {{ stats[item.key] }} </span>
          </div>
        </v-col>

        <v-col cols="6" class="pa-0 ma-0 flex-grow-0">
          <!-- DCWAN data -->
          <!-- <div class="d-flex pa-1 ma-1" v-for="item in dcwanDigest" :key="item.title">
            <span class="d-inline-block" style="min-width: 24px">
              <v-icon v-text="item.icon"></v-icon>
            </span>
            <span class="ml-2"> {{ item.title }}: {{ item.value }} </span>
            <span class="ml-2" v-if="item.title == 'Other'">
              <v-tooltip v-if="dcwan" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ma-0" icon v-bind="attrs" v-on="on">
                    <v-icon color="grey"> mdi-information-outline </v-icon>
                  </v-btn>
                </template>
                <span>
                  DCWAN usage information is current as of
                  {{ dcwan.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
                  <br />
                  A request to update this information was last made at
                  {{ dcwan.updatedAt | moment('YYYY-MM-DD HH:mm:ss') }}
                  <br />
                  New update requests are enabled after five minutes.
                </span>
              </v-tooltip>
            </span>
          </div> -->
          <div class="d-flex pa-1 ma-1" v-for="item in disk_items" :key="item.title">
            <span class="d-inline-block" style="min-width: 24px">
              <v-icon v-text="item.icon"></v-icon>
            </span>
            <span class="ml-2"> {{ item.title }}: {{ stats[item.key] }} </span>
          </div>
          <div class="d-flex pa-1 ma-1">
            <span class="d-inline-block" style="min-width: 24px">
            </span>
            <span class="ml-2"> # of days to keep dataproducts: {{ daysToKeepDataproducts }} </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <disk-chart :datasets="datasets" :dataproducts="dataproducts"></disk-chart>
  </div>
</template>

<script>
import DiskChart from './DiskChart.vue'

export default {
  name: 'DiskUsage',

  components: {
    DiskChart,
  },

  computed: {
    // dcwanDigest: function () {
    //   if (Object.prototype.hasOwnProperty.call(this.dcwan, 'details')) {
    //     const dirs = this.dcwan.details.split('\n')
    //     let total = 0
    //     // place to track other, miscellaneous directory sizes
    //     let other = 0
    //     let summary = []
    //     dirs.forEach((row) => {
    //       if (row !== '') {
    //         const parts = row.split('\t')
    //         // console.log('Parts are', parts)
    //         const size = parts[0]
    //         const path = parts[1]
    //         const pathParts = path.split('/')
    //         // console.log('pathParts are', pathParts)
    //         const keep = ['conversion', 'staging', 'upload']
    //         const pathName = pathParts[pathParts.length - 1]
    //         if (keep.indexOf(pathName) == -1) {
    //           other += parseInt(size, 10)
    //         } else {
    //           if (pathName === 'conversion') {
    //             summary.push({
    //               title: 'Pipeline Conversions',
    //               value: this.$options.filters.gigaBytes(parseInt(size, 10)),
    //               icon: '',
    //             })
    //           } else if (pathName === 'staging') {
    //             summary.push({
    //               title: 'Sequencing Runs',
    //               value: this.$options.filters.gigaBytes(parseInt(size, 10)),
    //               icon: '',
    //             })
    //           } else if (pathName === 'upload') {
    //             summary.push({
    //               title: 'Uploads',
    //               value: this.$options.filters.gigaBytes(parseInt(size, 10)),
    //               icon: '',
    //             })
    //           }
    //         }

    //         total += parseInt(size, 10)
    //       } else {
    //         console.log('skip empty row', row)
    //       }
    //     })
    //     summary.push({
    //       title: 'Other',
    //       value: this.$options.filters.gigaBytes(other),
    //       icon: '',
    //     })
    //     summary.unshift({
    //       title: 'DCWAN',
    //       value: this.$options.filters.gigaBytes(total),
    //       icon: 'mdi-harddisk',
    //     })
    //     // console.log(dirs)
    //     // console.log(this.dcwan.updateAt)
    //     return summary
    //   } else {
    //     return []
    //   }
    // },
    stats: function () {
      if (this.datasets.length) {
        let _stats = {
          sda_usage: 0,
          sda_usage_dp: 0,
          sda_usage_ds: 0,
          disk_usage: 0,
          disk_usage_ds: 0,
          disk_usage_dp: 0,
          // disk_usage_dp_dcwan: 0,
          // disk_usage_up_dcwan: 0,
          disk_usage_dp_slate: 0,
          registered: this.datasets.length,
        }
        this.datasets.forEach((d) => {
          if (d.archived) {
            _stats.sda_usage += d.size
            _stats.sda_usage_ds += d.size
          }
          if (d.staged) {
            _stats.disk_usage_ds += d.size
          }
        })

        this.dataproducts.forEach((d) => {
          // assume archived to SDA
          if (d.paths.archive !== '') {
            _stats.sda_usage += d.size
            _stats.sda_usage_dp += d.size
          }

          if (d.staged) {
            //eslint-disable-next-line
            if (d.paths.staged.search('/\/N\/dcwan\/projects\/cmg-sca/')) {
              // if (d.upload !== undefined) {
              //   _stats.disk_usage_up_dcwan += d.size
              // } else {
              //   _stats.disk_usage_dp_dcwan += d.size
              // }

              //eslint-disable-next-line
            } else if (d.paths.staged.search('/\/N\/project\/CMG-SCA/')) {
              _stats.disk_usage_dp_slate += d.size
            } else {
              console.log('unknown path', d.paths.staged)
            }
            _stats.disk_usage_dp += d.size
          } else {
            // console.log('Not staged', d.paths.staged)
          }
        })

        _stats.disk_usage_ds = this.diskUsage
        _stats.disk_usage = _stats.disk_usage_ds + _stats.disk_usage_dp
        console.log('before adding', _stats.disk_usage_ds, _stats.disk_usage_dp)
        console.log('Total disk usage', _stats.disk_usage)
        console.log(_stats)

        _stats.disk_usage = this.$options.filters.gigaBytes(_stats.disk_usage)
        _stats.disk_usage_ds = this.$options.filters.gigaBytes(
          _stats.disk_usage_ds
        )
        _stats.disk_usage_dp = this.$options.filters.gigaBytes(
          _stats.disk_usage_dp
        )
        // _stats.disk_usage_up_dcwan = this.$options.filters.gigaBytes(
        //   _stats.disk_usage_up_dcwan
        // )
        // _stats.disk_usage_dp_dcwan = this.$options.filters.gigaBytes(
        //   _stats.disk_usage_dp_dcwan
        // )
        _stats.disk_usage_dp_slate = this.$options.filters.gigaBytes(
          _stats.disk_usage_dp_slate
        )

        _stats.sda_usage = this.$options.filters.gigaBytes(_stats.sda_usage)
        _stats.sda_usage_dp = this.$options.filters.gigaBytes(
          _stats.sda_usage_dp
        )
        _stats.sda_usage_ds = this.$options.filters.gigaBytes(
          _stats.sda_usage_ds
        )

        console.log(_stats)
        return _stats
      } else {
        return {
          sda_usage: 0,
          sda_usage_ds: 0,
          sda_usage_dp: 0,

          disk_usage: 0,
          disk_usage_ds: 0,
          disk_usage_dp: 0,
          // disk_usage_dp_dcwan: 0,
          // disk_usage_dp_slate: 0,
          registered: this.datasets.length,
        }
      }
    },
  },
  data() {
    return {
      datasets: [],
      dataproducts: [],
      diskUsage: 0,
      daysToKeepDataproducts: null,

      stat_items: [
        { title: 'SDA', icon: 'mdi-nas', key: 'sda_usage' },
        { title: 'Sequencing Runs', icon: '', key: 'sda_usage_ds' },
        { title: 'Data Products', icon: '', key: 'sda_usage_dp' },
        {
          title: 'Sequencing Runs Registered',
          icon: 'mdi-dna',
          key: 'registered',
        },
      ],
      disk_items: [
        // { title: 'Disk Usage', icon: '', key: 'disk_usage' },
        { title: 'Slate-Project', icon: 'mdi-harddisk', key: 'disk_usage_ds' },
        // { title: 'Sequencing Runs', icon: '', key: 'disk_usage_ds' },

        // { title: 'DCWAN', icon: 'mdi-harddisk', key: 'disk_usage_dp' },
        // {
        //   title: 'Pipeline Conversions',
        //   icon: '',
        //   key: 'disk_usage_dp_dcwan',
        // },
        // {
        //   title: 'Uploads',
        //   icon: '',
        //   key: 'disk_usage_up_dcwan',
        // },
      ],
      dcwan: {},
    }
  },
  mounted() {
    this.getDaysToKeepDataproducts()
    // this.getDCWAN()
    this.getDatasets()
    this.getDataproducts()
    this.getDiskUsage()
  },
  methods: {
    getDiskUsage() {
      this.$http
        .get(`${this.$config.api}/datasets/diskUsage`)
        .then((response) => {
          this.diskUsage = response.data.usage
          console.log('Disk Usage from API:', response.data)
        })
    },

    getDatasets() {
      this.$http.get(`${this.$config.api}/datasets`).then((response) => {
        this.datasets = response.data
        // console.log(response.data)
      })
    },

    getDataproducts() {
      this.$http
        .get(`${this.$config.api}/dataproducts/all`)
        .then((response) => {
          this.dataproducts = response.data
          // console.log(response.data)
        })
    },

    getDaysToKeepDataproducts() {
      this.$http
        .get(`${this.$config.api}/clean/settings`)
        .then((response) => {
          this.daysToKeepDataproducts = response.data
          console.log("daysToKeepDataproducts:", response.data)
        })
    },

    // getDCWAN() {
    //   this.$http.get(`${this.$config.api}/content/dcwan`).then((response) => {
    //     this.dcwan = response.data
    //     let now = new Date()
    //     let oldCheck = new Date()

    //     // e.g. five minutes
    //     const debounceInterval = 5 * 60 * 1000 // ms
    //     // allow recheck of du after some time passes
    //     oldCheck.setTime(now.getTime() - debounceInterval)
    //     if (this.dcwan.updatedAt < oldCheck) {
    //       console.log('The API is updating the result')
    //     } else {
    //       console.log(
    //         'The results were updated in the last 5 minutes. The next refresh will be available once results are older than 5 minutes'
    //       )
    //     }
    //     console.log('DCWAN results', response.data)
    //   })
    // },
  },
}
</script>
