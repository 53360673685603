var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"UserList"},[(_vm.$store.getters.hasRole('admin'))?_c('UserForm',{attrs:{"userdata":_vm.current_user},on:{"refresh":[_vm.query,_vm.query]}}):_vm._e(),_c('items-count',{attrs:{"items":_vm.users}}),_c('v-data-table',{staticClass:"elevation-4",attrs:{"items":_vm.users,"dense":"","headers":_vm.headers,"search":_vm.search,"item-key":"name","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":"Search","outlined":"","clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.primary_role",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":_vm.userClass(item)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.userIcon(item))+" ")])]}}],null,true)},[_c('span',[_vm._v("Primary Role: "+_vm._s(item.primary_role))])])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(role){return _c('span',{key:role},[_c('v-chip',{class:role === item.primary_role ? 'blue lighten-2' : '',attrs:{"x-small":""}},[_vm._v(_vm._s(role))])],1)})}},{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createDate,'YYYY-MM-DD'))+" ")]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.lastLogin,'from'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.hasRole('admin'))?_c('span',[_c('UserForm',{attrs:{"userdata":item},on:{"refresh":_vm.query},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 clickable",attrs:{"small":"","color":"green lighten-2"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit user")])])]},proxy:true}],null,true)})],1):_vm._e(),(_vm.$store.getters.hasRole('admin'))?_c('span',[_c('Confirm',{attrs:{"title":"Log in as user?","message":_vm.sudoMessage(item),"color":"orange"},on:{"confirm":function($event){return _vm.sudoUser(item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 clickable",attrs:{"small":"","color":"orange"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-convert ")])]}}],null,true)},[_c('span',[_vm._v("Log in as user")])])]},proxy:true}],null,true)})],1):_vm._e(),(_vm.$store.getters.hasRole('god'))?_c('span',[_c('Confirm',{attrs:{"title":"Delete User?","message":_vm.deleteMessage(item),"color":"red lighten-2"},on:{"confirm":function($event){return _vm.deleteUser(item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"clickable",attrs:{"small":"","color":"red lighten-2"}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete user")])])]},proxy:true}],null,true)})],1):_vm._e()]}}])}),_c('hr')],1)}
var staticRenderFns = []

export { render, staticRenderFns }