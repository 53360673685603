<template>
  <div>
    <v-dialog v-model="uploadDialog" max-width="600px">
      <upload-form @close-dialog="getUploads"></upload-form>
    </v-dialog>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click.stop="uploadDialog = true" class="mb-4 mr-4">
          <v-icon class="mr-2">mdi-upload</v-icon>
          New Upload
        </v-btn>
      </template>
      <span>
        Initiate a new upload process for a data product stored on IU Storage that
        was generated outside of the portal
      </span>
    </v-tooltip>

    <items-count :items="selected"></items-count>

    <v-data-table
      :items="uploads"
      :headers="headers"
      sort-by="createdAt"
      :sort-desc="true"
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      item-key="_id"
      :search="search"
      disable-pagination
      hide-default-footer
      @current-items="updateSummary"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          outlined
          clearable
          prepend-inner-icon="mdi-magnify"
          class="mx-4 mt-4"
        ></v-text-field>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment('YYYY-MM-DD') }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | moment('from') }}
      </template>

      <template v-slot:item.status="{ item }">
        <div v-if="item.status == 'completed'">
          <a href="/dataproducts">complete</a>
        </div>
        <div v-if="item.status == 'new'">new</div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          Selected Files
          <li v-for="file in item.selected" :key="file">
            {{ file }}
          </li>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// this approach seems to cause problems with data attributes on component (?)
// import UploadForm from '@/components/UploadForm'
import UploadForm from '../components/UploadForm.vue'
import ItemsCount from '@/components/ItemsCount'

export default {
  name: 'DataUploader',
  components: { UploadForm, ItemsCount },
  data() {
    return {
      uploads: [],
      uploadDialog: false,
      headers: [
        { value: 'path', text: 'Source Path' },
        { value: 'user.username', text: 'User' },
        { value: 'createdAt', text: 'Submitted' },
        { value: 'updatedAt', text: 'Last Updated' },
        { value: 'status', text: 'Status' },
        { value: 'file_type', text: 'File Type' },
        { value: 'file_count', text: 'File Count' },
        { value: 'notes', text: 'Notes' },
        { value: 'data-table-expand', text: '' },
      ],
      expanded: [],
      search: '',

      selected: [],
    }
  },
  methods: {
    getUploads() {
      console.log('getUploads called')
      this.uploadDialog = false
      this.$http.get(`${this.$config.api}/uploads/all`).then((response) => {
        this.uploads = response.data
        console.log('Uploads: ', response.data)
      })
    },
    updateSummary(items) {
      this.selected = items
    },
  },
  mounted() {
    this.getUploads()
  },
  // watch: {
  //   uploadDialog() {
  //     console.log('Upload dialog changed:', this.uploadDialog)
  //   },
  // },
}
</script>
