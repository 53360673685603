<template>
  <div>
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12" md="7">
          <v-card class="mt-4 mx-auto">
            <v-card-text class="pt-0">
              <disk-usage></disk-usage>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-data-table
          :items="workers"
          :headers="headers"
          show-expand
          item-key="_id"
          :expanded.sync="expanded"
          class="my-4 mx-3 elevation-2"
          disable-pagination
          hide-default-footer
          dense
          sort-by="host"
          :sort-desc="true"
        >
          <template v-slot:item.service="{ item }">
            <v-icon left color="info">{{ w_m[item.service].icon }}</v-icon>
            <span class="font-weight-light">{{ w_m[item.service].title }}</span>
          </template>

          <template v-slot:item.updatedAt="{ item }">
            {{ item.updatedAt | moment('from') }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div
                v-if="
                  item.status === 'working' &&
                  item.service === 'stagings' &&
                  item.progress === null
                "
              >
                <div class="mt-3 text-caption">
                  <b>
                    Requesting job on the SDA. Progress will update when the job
                    begins
                  </b>
                </div>
              </div>

              <WorkerProgess :worker="item"></WorkerProgess>

<!--              <div v-if="item.progress !== undefined && item.progress">-->
<!--                <div v-if="item.progress" class="mt-3 text-caption">-->
<!--                  <b>Working on:</b> {{ item.progress.name }}<br />-->

<!--                  <span v-if="item.progress.units == 'bytes'">-->
<!--                    <b>Progress:</b> {{ item.progress.done | prettyBytes }} of-->
<!--                    {{ item.progress.total | prettyBytes }}<br />-->
<!--                  </span>-->
<!--                  <span v-if="item.progress.units !== 'bytes'">-->
<!--                    <b>Progress:</b> {{ item.progress.done }} of-->
<!--                    {{ item.progress.total }} {{ item.progress.units }}<br />-->
<!--                  </span>-->
<!--                  <b>Started:</b> {{ formatUnix(item.progress.start_time)-->
<!--                  }}<br />-->
<!--                  <b>Estimated Completion:</b>-->
<!--                  {{ estimateCompletion(item.progress) }}-->
<!--                </div>-->

<!--                <span-->
<!--                  v-if="item.progress.phase == 'untar'"-->
<!--                  class="mt-3 text-caption"-->
<!--                >-->
<!--                  <b>Download Complete, Extracting</b>-->
<!--                  <v-progress-linear-->
<!--                    class="mt-3"-->
<!--                    color="green"-->
<!--                    buffer-value="0"-->
<!--                    :value="100"-->
<!--                    stream-->
<!--                  ></v-progress-linear>-->
<!--                </span>-->

<!--                <v-progress-linear-->
<!--                  class="mt-3"-->
<!--                  color="teal"-->
<!--                  buffer-value="0"-->
<!--                  :value="(item.progress.done * 100) / item.progress.total"-->
<!--                  stream-->
<!--                ></v-progress-linear>-->

<!--                <div-->
<!--                  v-if="item.progress.phase == 'get'"-->
<!--                  class="mt-3 text-caption"-->
<!--                >-->
<!--                  <b>Extraction Pending</b>-->
<!--                  <v-progress-linear-->
<!--                    class="mt-3"-->
<!--                    color="blue"-->
<!--                    buffer-value="0"-->
<!--                    :value="0"-->
<!--                    stream-->
<!--                  ></v-progress-linear>-->
<!--                </div>-->

<!--                <v-divider class="my-1"></v-divider>-->
<!--              </div>-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="yellow darken-2"
                    @click="confirmCommand(item, 'PAUSE')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-motion-pause</v-icon>
                  </v-btn>
                </template>
                <span>Pause Worker</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="green"
                    @click="confirmCommand(item, 'RESUME')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-motion-play</v-icon>
                  </v-btn>
                </template>
                <span>Resume Worker</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="orange"
                    @click="confirmCommand(item, 'KILL')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Stop Active Job</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="red"
                    @click="confirmCommand(item, 'HALT')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-exit-run</v-icon>
                  </v-btn>
                </template>
                <span>Shutdown Worker</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="headline"> Issue Worker Command </v-card-title>

        <v-card-text>
          Are you sure you want to issue the command {{ cmd }} to Worker
          {{ worker.name }}?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Disagree
          </v-btn>

          <v-btn color="green darken-1" text @click="sendCommand">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Worker from '../components/Worker';
import WorkerProgess from '@/components/WorkerProgress';
import DiskUsage from '../components/DiskUsage'

export default {
  name: 'Dashboard',
  components: {
    DiskUsage,
    WorkerProgess
  },

  computed: {
    // autoExpand: function () {
    //   console.log('Expanded: ', this.expanded)
    // },
  },
  methods: {
    getWorkers() {
      this.$http.get(`${this.$config.api}/workers/all`).then((response) => {
        this.workers = response.data
        // console.log(response.data)
      })
    },
    formatUnix: function (value) {
      if (value) {
        return this.$moment.unix(value).from()
      }
    },
    estimateCompletion: function (p) {
      let duration = Date.now() / 1000 - p.start_time
      let remain = p.total - p.done
      let rate = p.done / duration
      let time_left = remain / rate
      console.log(duration, remain, rate, time_left)
      return this.$moment().add(time_left, 'seconds').from()
    },
    confirmCommand: function (worker, cmd) {
      this.worker = worker
      this.cmd = cmd
      this.dialog = true
    },
    sendCommand: function () {
      this.dialog = false
      let self = this
      this.$http
        .get(`${this.$config.api}/workers/issue/${this.worker._id}/${this.cmd}`)
        .then((response) => {
          self.worker = { name: '' }
          self.cmd = ''
          console.log('Dashboard -> sendCommand ->', response.data)
        })
    },
  },
  mounted() {
    this.getWorkers()
    this.interval = setInterval(
      function () {
        this.getWorkers()
      }.bind(this),
      5000
    )
  },

  beforeDestroy: function () {
    clearInterval(this.interval)
  },

  data: () => ({
    interval: null,
    workers: [],
    cmd: '',
    worker: { name: '' },
    dialog: false,
    expanded: [],
    autoExpanded: [],
    cmd_message: '',
    w_m: {
      registrations: { title: 'Registrar', icon: 'mdi-clipboard-check' },
      archivals: { title: 'Archiver', icon: 'mdi-package-up' },
      stagings: { title: 'Stager', icon: 'mdi-package-down' },
      conversions: { title: 'Converter', icon: 'mdi-transition' },
      downloads: { title: 'Downloader', icon: 'mdi-download' },
      validations: { title: 'Validator', icon: 'mdi-file-check' },
      uploads: { title: 'Uploader', icon: 'mdi-folder-upload' },
      inspection: { title: 'Inspector', icon: 'mdi-barcode-scan' },
    },
    headers: [
      { value: 'service', text: 'Service Type' },
      { value: 'host', text: 'Hostname' },
      { value: 'name', text: 'Worker Name' },
      { value: 'status', text: 'Current Status' },
      { value: 'updatedAt', text: 'Last Updated' },
      { value: 'data-table-expand', text: '' },
    ],
  }),
  watch: {
    // expanded() {
    //   console.log('Expanded changed', this.expanded)
    // },
    workers(newWorkers) {
      // console.log('workers updated to', newWorkers, 'from:', oldWorkers)
      newWorkers.forEach((worker) => {
        if (worker.status !== 'waiting') {
          let position = -1
          // check if the worker is already in this.expanded

          // this approach does not work:
          // const index = this.expanded.indexOf(worker)
          // iterate over this.expanded and check for same name
          this.expanded.forEach((item, index) => {
            // console.log(item)
            if (item.name == worker.name) {
              position = index
            }
          })
          // console.log('index of worker in expanded', position)

          // if the worker is not already expanded, expand it now
          if (position === -1) {
            this.expanded.push(worker)
            // console.log('Expanded', worker.name)
            const index = this.autoExpanded.indexOf(worker.name)
            if (index === -1) {
              this.autoExpanded.push(worker.name)
            }
          } else {
            // console.log('Worker row already expanded', worker.name)
          }
        }

        // back to waiting, go ahead and collapse if automatically expanded
        else {
          const auto = this.autoExpanded.indexOf(worker.name)
          if (auto !== -1) {
            let position = -1

            this.expanded.forEach((item, index) => {
              console.log(item)
              if (item.name == worker.name) {
                position = index
              }
            })
            this.expanded.splice(position, 1)
            this.autoExpanded.splice(auto, 1)
          }
        }
      })
    },
  },
}
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
