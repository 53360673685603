<template>
  <div>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <v-icon class="mr-1">mdi-upload</v-icon> Upload Data Product
      </v-card-title>

      <v-card-text>
        <!-- <v-tabs v-model="filetypeSelected">
          <v-tab v-for="typetab of filetypes" :key="typetab.name">
            {{ typetab.name }}
          </v-tab>
        </v-tabs> -->

        <v-dialog v-model="filetypeForm.show" max-width="600px">
          <div>
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>Upload Filetype Manager</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon dark @click="filetypeForm.show = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <span class="headline">New Filetype</span>
                <v-form>
                  <v-text-field label="Name" v-model="filetypeForm.name" required
                    @keyup="(filetypeForm.name = filetypeForm.name.toUpperCase())"></v-text-field>

                  <v-text-field label="Extension" v-model="filetypeForm.extension" required></v-text-field>

                  <div class="d-flex justify-center" style="width: 100% !important">
                    <v-btn @click="create_new_filetype" width="50%" color="primary">
                      <v-icon>mdi-plus</v-icon> Add
                    </v-btn>
                  </div>


                </v-form>

                <v-divider class="mt-7 mb-7"></v-divider>

                <span class="headline">Remove Filetypes</span>
                <v-list>
                  <v-list-item v-for="(item, i) in filetypes" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                      <v-list-item-subtitle>extension: {{ item.extension }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <DeleteButtonWithConfirm @click="deleteFileType(item)" />
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn right text @click="filetypeForm.show = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-dialog>

        <div class="">
          <v-select label="Filetype" v-model="filetypeSelected" :items="filetypes" item-text="name" item-value="name"
            :hint="`extension: ${filetypeSelected.extension}`" persistent-hint return-object>
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item ripple @mousedown.prevent @click="filetypeForm.show = true">
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Add / Remove Filetype
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>

        </div>


        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field label="Path to file" prepend-icon="mdi-folder-network" v-model="upload.path" required
            :rules="pathRules" :error-messages="pathErrors"></v-text-field>

          <v-switch v-model="upload.allow_all" label="Show all Filetypes?"></v-switch>

          <file-selector class="ml-8" v-if="upload.path" :files="pathMatches" @updateSelected="handleSelected">
          </file-selector>

          <v-switch v-model="upload.new_project" label="New Project?"></v-switch>

          <v-autocomplete prepend-icon="mdi-flask" v-model="upload.projects" :items="projects"
            :search-input.sync="searchString" item-text="name" item-value="_id" label="Project Assignment"
            :disabled="upload.new_project" cache-items multiple required>
          </v-autocomplete>

          <div v-if="!dataset">
            <v-autocomplete prepend-icon="mdi-dna" v-model="localDataset" :items="datasets" item-text="name"
              item-value="_id" label="Source Sequencing Run"></v-autocomplete>
          </div>
          <div v-if="dataset">
            <v-text-field label="Source Sequencing Run" placeholder="Placeholder" disabled v-model="localDataset.name">
            </v-text-field>
          </div>

          <v-autocomplete v-if="filetypeSelected && filetypeSelected.extension != 'fastq'" prepend-icon="mdi-dna"
            v-model="upload.fastq" :items="dataproducts" item-text="name" item-value="_id" label="Source FastQ">
          </v-autocomplete>

          <genome-selector @genomeSelected="handleGenome"></genome-selector>

          <v-textarea v-model="upload.notes" outlined prepend-icon="mdi-playlist-edit" label="Notes"></v-textarea>

          <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
            Submit
          </v-btn>

          <v-btn color="error" class="mr-4" @click="reset"> Reset </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// this approach seems to cause problems with data attributes on component (?)
// import FileSelector from '@/components/FileSelector'
import FileSelector from '../components/FileSelector.vue'
import DeleteButtonWithConfirm from './DeleteButtonWithConfirm.vue'
import GenomeSelector from './GenomeSelector.vue'

export default {
  name: 'UploadForm',
  components: { FileSelector, GenomeSelector, DeleteButtonWithConfirm },
  props: {
    dataset: {
      type: Object,
      default: () => { },
    },
  },

  // FYI: layouts are defined in router/index.js
  // layout: 'datatables',
  computed: {
    groupRules() {
      return [this.upload.groups.length > 0 || 'Select at least one group']
    },
    pathMatches() {
      if (this.upload.allow_all) return this.pathContents
      const result = this.pathContents.filter((str) => {
        return str.indexOf(this.filetypeSelected.extension) !== -1
      })
      return result
    },
  },

  data() {
    return {
      datasets: [],
      // this only holds the id of the dataset
      localDataset: { ...this.dataset },
      valid: true,
      pathErrors: '',
      upload: {
        path: '',
        // groups: [],
        projects: [],
        new_project: true,
        dataset: null,
        fastq: null,
        notes: '',
        genomeType: '',
        genomeValue: '',
        allow_all: false,
      },
      filetypeForm: {
        name: '',
        extension: '',
        show: false
      },
      groups: [],
      projects: [],
      dataproducts: [],
      pathRules: [(v) => !!v || 'Path is required'],
      pathContents: [],
      pathSelected: [],
      filetypeSelected: {},
      filetypes: [],
      searchString: '',
    }
  },
  methods: {
    getDatasets() {
      this.$http.get(`${this.$config.api}/datasets`).then((response) => {
        this.datasets = response.data
        // console.log('UploadForm -> getDatasets', response.data)
      })
    },

    getProjects() {
      this.projects = []
      this.$http.get(`${this.$config.api}/projects/search/${this.searchString}`).then(
        // this.$http.get(`${this.$config.api}/projects/all`).then(
        (res) => {
          this.projects = res.data
          console.log(res.data.length + ' projects retrieved from db')
          console.log('Projects:', this.projects)
        },
        (err) => {
          console.log('Error contacting API for Projects')
          console.dir(err)
        }
      )
    },

    getGroups() {
      this.groups = []
      this.$http.get(`${this.$config.api}/groups/all`).then(
        (res) => {
          this.groups = res.data
          // console.log(res.data.length + ' groups retrieved from db')
          // console.log('Groups:', this.groups)
        },
        (err) => {
          console.log('Error contacting API')
          console.dir(err)
        }
      )
    },

    getDataProducts() {
      this.$http
        .get(`${this.$config.api}/dataproducts/all/fastq`)
        .then((response) => {
          this.dataproducts = response.data
          // console.log(response.data)
        })
    },

    handleSelected(newValue) {
      this.pathSelected = newValue
      console.log('pathSelected updated to', this.pathSelected)
    },
    handleGenome(genomeType, genomeValue) {
      console.log('Form received - setting values', genomeType, genomeValue)
      this.upload.genomeType = genomeType
      this.upload.genomeValue = genomeValue
    },

    validate() {
      // console.log('Selected', this.pathSelected)
      // console.log('VALIDATING')
      // console.log(this.upload.groups)
      this.$refs.form.validate()

      this.$nextTick(() => {
        if (this.valid) {
          // console.log('Selected', this.pathSelected)
          // make a copy of this.upload
          this.snapshot = { ...this.upload }
          // and add current value of selected typeName
          this.snapshot.type = this.filetypeSelected.name
          this.snapshot.selected = [...this.pathSelected]
          console.log('localDataset', this.localDataset)
          if (this.localDataset && Object.keys(this.localDataset).length) {
            console.log('Applying dataset selection', this.localDataset)
            this.snapshot.dataset = this.localDataset
          } else {
            this.snapshot.dataset = null
          }
          // console.log('Snapshot', this.snapshot)
          // console.log('Checking that path is valid')
          this.$http
            .post(`${this.$config.api}/uploads/verifypath`, this.snapshot)
            .then(
              (res) => {
                // console.log(res.data)
                if (res.data.status !== 'success') {
                  this.valid = false
                  console.log('FAILED VALIDATION')
                  this.pathErrors = 'Invalid/Inaccessible path'
                } else {
                  this.$http
                    .post(`${this.$config.api}/uploads/new`, this.snapshot)
                    .then(
                      (res) => {
                        console.log(res.data)
                        // this.getUploads()
                        // this.$emit('new-upload')
                        this.$emit('close-dialog')
                        this.reset()
                      },
                      (err) => {
                        console.log('Error contacting API')
                        console.dir(err)
                      }
                    )
                }
              },
              (err) => {
                console.log('Error contacting API')
                console.dir(err)
              }
            )
        }
      })
    },
    reset() {
      this.$refs.form.reset()
    },
    create_new_filetype() {
      console.log('add new filetype', this.filetypeForm.name, this.filetypeForm.extension)
      // add new filtype to known filetypes, increment displayOrder
      const newFiletype = {
        name: this.filetypeForm.name,
        extension: this.filetypeForm.extension,
        displayOrder: this.filetypes.length + 1
      }
      this.filetypes.push(newFiletype)

      // select the added filetype in the filetype dropdown
      this.filetypeSelected = newFiletype

      // close dialog
      this.filetypeForm.show = false

      // add to db
      this.$http.post(`${this.$config.api}/uploads/filetype`, newFiletype).catch(err => {
        console.log('Unable to persist new filetype to DB', err)
      })

      // forget form
      this.filetypeForm.name = ''
      this.filetypeForm.extension = ''


    },
    getUploadFileTypes() {
      this.$http.get(`${this.$config.api}/uploads/filetype`)
        .then(res => {
          this.filetypes = res.data || []
          if (this.filetypes.length > 0)
            this.filetypeSelected = this.filetypes[0]
        })
        .catch(err => {
          console.log('Unable to get upload filetypes from DB, using defaults', err)
          this.filetypes = [{
            "name": "FASTQ",
            "extension": "fastq",
            "displayOrder": 1,
          }, {
            "name": "BAM",
            "extension": "bam",
            "displayOrder": 2,
          }, {
            "name": "BIGWIG",
            "extension": "bw",
            "displayOrder": 3,
          }, {
            "name": "VCF",
            "extension": "vcf",
            "displayOrder": 4,
          }]
          this.filetypeSelected = this.filetypes[0]
        })
    },
    deleteFileType(item) {
      console.log('deleteFileType', item)

      // update db
      this.$http.delete(`${this.$config.api}/uploads/filetype/${item.name}`)
        .then(() => {
          this.filetypes = this.filetypes.filter(ft => ft.name != item.name)
          if (this.filetypeSelected.name == item.name) {
            this.filetypeSelected = this.filetypes[0]
          }
        })
        .catch(err => {
          console.log("Unable to remove upload filetype", err)
        })
    }
  },
  mounted() {
    // console.log('localDataset', this.localDataset)
    // console.log(
    //   'localDataset keys length',
    //   Object.keys(this.localDataset).length
    // )
    this.getProjects()
    this.getDatasets()
    this.getDataProducts()
    this.getUploadFileTypes()
  },
  watch: {
    searchString: function () {
      this.getProjects();
    },

    'upload.path'() {
      // console.log('Upload path changed')
      this.pathErrors = ''
      if (this.upload.path) {
        this.$http
          .post(`${this.$config.api}/uploads/pathcontents`, this.upload)
          .then(
            (res) => {
              // console.log(res.data)
              if (res.data.status !== 'success') {
                this.valid = false
                console.log('Getting Path Contents FAILED')
                console.log(res.data)
                this.pathErrors = 'Invalid/Inaccessible path'
              } else {
                // console.log('Matched path')
                // console.log(res.data)
                this.pathContents = res.data.files
              }
            },
            (err) => {
              console.log('Error contacting API')
              console.dir(err)
            }
          )
      }
    },
  },
}
</script>
