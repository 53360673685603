<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" @click.stop="handleClick" class="mb-4 mr-4">
        <v-icon class="mr-2">mdi-upload</v-icon>
        Upload
      </v-btn>
    </template>
    <span> Upload a data product generated outside of the web portal </span>
  </v-tooltip>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit('upload-button')
    },
  },
}
</script>