<template>
  <div>
    <v-dialog v-model="logfile_dialog">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Logfile
        </v-card-title>

        <v-card-text v-if="logfile_dialog && showLog">
          <LogViewer
            :name="showLog.dataset.name"
            :status="showLog.status"
          ></LogViewer>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="logfile_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="stats_dialog">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Conversion Statistics
        </v-card-title>

        <v-card-text>
          <tree-view :data="stats" :options="{ maxDepth: 3 }"></tree-view>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="stats_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="conversionDialog" max-width="600px">
      <conversion-form
        @close-dialog="conversionDialog = false"
      ></conversion-form>
    </v-dialog>
    <conversion-button
      @conversion-button="conversionDialog = true"
    ></conversion-button>

    <v-text-field
      v-model="search"
      label="Search"
      @input="handleSearch"
      outlined
      prepend-inner-icon="mdi-magnify"
      clearable
      class="mx-4 mt-4"
    ></v-text-field>

    <div class="mb-4 d-flex justify-space-between">
      <v-pagination
        v-model="page"
        :length="pages"
        total-visible="7"
        circle
        @input="getConversions"
        class="ml-4"
      ></v-pagination>
      <items-count :total="total" :key="total" class="mr-4"></items-count>
    </div>

    <v-data-table
      :items="conversions"
      :headers="headers"
      sort-by="createdAt"
      :sort-desc="true"
      :single-expand="false"
      show-expand
      item-key="_id"
      :expanded.sync="expanded"
      :loading="loading"
      :server-items-length="total"
      :options.sync="options"
      class="elevation-4"
      disable-pagination
      hide-default-footer
      @current-items="updateSummary"
      @update:options="handleUpdateOptions"
    >
      <template v-slot:item.dataset.name="{ item }">
        <div v-if="item.dataset">
          <router-link
            :to="{
              name: 'RunDetails',
              params: { name: item.dataset.name },
            }"
          >
            {{ item.dataset.name }}
          </router-link>
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-tooltip
          bottom
          v-if="item.status == 'completed' && !item.dataproducts"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="red"> mdi-alert </v-icon>
          </template>
          <span
            >Conversion run completed but did not produce any output. Check log
            for details</span
          >
        </v-tooltip>

        <v-icon v-else-if="item.status == 'completed'" color="green">
          mdi-check
        </v-icon>
        <v-progress-circular
          v-else
          :size="20"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment('from') }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | moment('from') }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom v-if="item.status !== 'new'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="loadLog(item)">
              <v-icon color="info">mdi-file-cog</v-icon>
            </v-btn>
          </template>
          <span>View Log Output</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status === 'completed'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :href="`/api/reports/${item._id}/Reports/html/`"
              target="_blank"
            >
              <v-icon color="success">mdi-file-chart</v-icon>
            </v-btn>
          </template>
          <span>View Conversion Report (opens in new tab)</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <ConversionDetails :id="item._id"></ConversionDetails>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ConversionForm from '@/components/ConversionForm'
import ConversionButton from '@/components/ConversionButton'
import ConversionDetails from '@/views/ConversionDetails'
import LogViewer from '@/components/LogViewer'
import ItemsCount from '@/components/ItemsCount'
import debounce from 'debounce'

export default {
  name: 'Conversions',
  components: {
    ConversionForm,
    ConversionButton,
    ConversionDetails,
    LogViewer,
    ItemsCount,
  },
  data() {
    return {
      expanded: [],
      conversions: [],
      showLog: null,
      conversionDialog: false,
      options: {},

      logfile_dialog: false,
      stats: {},
      stats_dialog: false,
      headers: [
        { value: 'dataset.name', text: 'Sequencing Run' },
        { value: 'user.username', text: 'User' },
        { value: 'pipeline', text: 'Pipeline' },
        { value: 'status', text: 'Status' },
        { value: 'createdAt', text: 'Created' },
        { value: 'updatedAt', text: 'Updated' },
        { value: 'actions', text: 'Actions' },
      ],
      selected: [],
      search: '',
      total: -1,
      page: 1,
      pages: -1,
      limit: 20,
      loading: false,
    }
  },
  methods: {
    getConversions: debounce(function () {
      this.loading = true
      this.conversions = []
      this.$http
        .get(`${this.$config.api}/conversions/search`, {
          params: {
            page: this.page,
            limit: this.limit,
            filter: this.search,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] ? -1 : 1,
          },
        })
        .then((response) => {
          this.conversions = response.data.docs
          this.total = parseInt(response.data.total, 10)
          this.page = response.data.page
          this.pages = response.data.pages
          console.log('Conversions: ', response.data)
        })
        .finally(() => {
          this.loading = false
        })
    }, 250),
    loadLog(job) {
      this.showLog = job
      this.logfile_dialog = true
    },
    loadStats(name) {
      let self = this
      this.$http
        .get(`${this.$config.api}/reports/${name}/Stats/Stats.json`)
        .then((response) => {
          console.log(response.data)
          self.stats = response.data
          self.stats_dialog = true
        })
      console.log(name)
    },
    updateSummary(items) {
      this.selected = items
    },
    handleSearch() {
      // if we're starting a search, make sure the page gets reset to 1
      // reset sort options too
      // otherwise results may not appear
      this.page = 1
      this.options.sortBy = ['createdAt']
      this.options.sortDesc = [true]
      this.getConversions()
    },
    handleUpdateOptions() {
      // sort options changed, display results from page 1
      this.page = 1
      this.getConversions()
    },
  },
  mounted() {
    this.getConversions()
  },
}
</script>

<style></style>
