<template>
  <div>
    <GroupList></GroupList>
  </div>
</template>

<script>
import GroupList from '@/components/admin/GroupList.vue'

export default {
  name: 'Groups',
  components: { GroupList },
  data() {
    return {}
  },
}
</script>

<style></style>
