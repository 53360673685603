<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>
      <v-icon class="mr-1">mdi-cogs</v-icon> Start Conversion (fastq)
    </v-card-title>

    <v-card-text>
      <v-col cols="12">
        <div v-if="!dataset">
          <v-autocomplete
            prepend-icon="mdi-dna"
            v-model="localDataset"
            :items="datasets"
            item-text="name"
            item-value="_id"
            :error-messages="errors"
            label="Source Sequencing Run"
          ></v-autocomplete>
        </div>

        <div v-if="dataset">
          <v-text-field
            label="Source Sequencing Run"
            placeholder="Placeholder"
            disabled
            v-model="dataset.name"
          ></v-text-field>
          <v-text-field
            label="Date Added"
            placeholder="Placeholder"
            disabled
            v-model="dataset.createdAt"
          ></v-text-field>
          <v-text-field
            label="CBCL File Count"
            placeholder="Placeholder"
            disabled
            v-model="dataset.files"
          ></v-text-field>
        </div>

        <v-select
          :items="pipelines"
          v-model="pipeline"
          label="Conversion Software"
        ></v-select>

        <div v-if="pipeline === 'bcl2fastq'">
          <v-checkbox
            v-for="(pf, idx) in bcl_flags"
            :key="idx"
            :label="`--${pf}`"
            :value="pf"
            v-model="bcl_flags_selected"
            class="shrink mr-2 mt-0"
          ></v-checkbox>
          <div class="d-flex">
            <v-checkbox
              v-model="barcode_mismatches"
              label="--barcode-mismatches"
              value="barcode-mismatches"
              class="shrink mr-2 mt-0"
            ></v-checkbox>
            <v-select
              :disabled="!barcode_mismatches"
              :items="bcm_values"
              label="parameter"
              v-model="bcm_value_selected"
              dense
            ></v-select>
          </div>
          <div class="d-flex">
            <v-checkbox
              v-model="use_bases_mask"
              label="--use-bases-mask"
              value="use-bases-mask"
              class="shrink mr-2 mt-0"
            ></v-checkbox>
            <v-text-field
              :disabled="!use_bases_mask"
              label="parameter"
              v-model="use_bases_mask_value"
              dense
            ></v-text-field>
          </div>
        </div>

        <div v-if="pipeline !== 'bcl2fastq'">
          <v-checkbox
            v-for="(pf, idx) in pl_flags"
            :key="idx"
            :label="`--${pf}`"
            :value="pf"
            v-model="pl_flags_selected"
          ></v-checkbox>
        </div>

        <div class="d-flex">
          <v-checkbox
            v-model="wildcards"
            label=""
            class="shrink mr-2 mt-0"
          ></v-checkbox>
          <v-text-field
            :disabled="!wildcards"
            label="Additional pipeline flags"
            v-model="wildcards_values"
            :rules="[rules.forbidden]"
            dense
            :hint="wildcard_help"
          ></v-text-field>
        </div>

        <v-textarea label="Samplesheet" v-model="samplesheet"></v-textarea>
      </v-col>
    </v-card-text>

    <v-card-actions>
      <v-col class="text-right">
        <v-btn color="green" @click="doConvert" :disabled="samplesheet == ''"
          >Submit</v-btn
        >
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ConversionForm',
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
  },

  // FYI: layouts are defined in router/index.js
  // layout: 'datatables',
  data() {
    return {
      rules: {
        forbidden: (value) => {
          const pattern = /^[-a-zA-Z0-9_ ,*'"/=]*$/
          return pattern.test(value) || 'Illegal character detected'
        },
      },
      wildcard_help:
        'Allowed characters: /=-a-zA-Z0-9_ ,*\'"  Text entered here will be appended to the pipeline command exactly as written. ',
      datasets: [],
      // the **_id** of the selected dataset
      localDataset: false,
      errors: [],

      pipeline: 'bcl2fastq',
      pl_flags: [
        'no-lane-splitting',
        'delete-undetermined',
        'filter-single-index',
      ],
      pl_flags_selected: ['no-lane-splitting'],

      bcl_flags: [
        'no-lane-splitting',
        'ignore-missing-bcls',
        'delete-undetermined',
        'filter-single-index',
      ],
      bcl_flags_selected: ['no-lane-splitting'],
      barcode_mismatches: false,
      use_bases_mask: false,
      wildcards: false,
      wildcards_values: '',
      use_bases_mask_value: '',
      bcm_values: ['0', '1', '2'],
      bcm_value_selected: '0',

      active_item: {
        name: '',
        createdAt: '',
        files: 0,
      },
      tab: '',
      pipelines: [
        { text: 'bcl2fastq v2.20.0.422', value: 'bcl2fastq' },
        { text: 'bcl-convert v4.3.6', value: 'bcl-convert' },
        { text: 'cellranger v8.0.1', value: 'cellranger-v8.0.1' },
        { text: 'cellranger v6.1.2', value: 'cellranger-v6.1.2' },
        { text: 'cellranger v4.0.0', value: 'cellranger-v4.0.0' },
        { text: 'cellranger (arc) v2.0.0', value: 'cellranger-arc-v2' },
        { text: 'cellranger (arc) v1.0.0', value: 'cellranger-arc' },
        { text: 'cellranger (atac) v1.2.0', value: 'cellranger-atac' },
        { text: 'spaceranger v3.0.1', value: 'spaceranger-v3.0.1' },
        { text: 'spaceranger v1.3.1', value: 'spaceranger-v1.3.1' },
        { text: 'spaceranger v1.1.0', value: 'spaceranger-v1.1.0' },
      ],
      samplesheet: '',
    }
  },

  computed: {
    currentOptions() {
      if (this.pipeline === 'bcl2fastq') {
        let options = [...this.bcl_flags_selected]
        if (this.barcode_mismatches) {
          options.push('barcode-mismatches ' + this.bcm_value_selected)
        }
        if (this.use_bases_mask) {
          options.push('use-bases-mask ' + this.use_bases_mask_value)
        }
        if (this.wildcards && this.wildcards_values.length) {
          options.push('wildcards ' + this.wildcards_values);
        }
        return options
      } else {
        let options = [...this.pl_flags_selected]
        if (this.wildcards && this.wildcards_values.length) {
          options.push('wildcards ' + this.wildcards_values);
        }
        return options
      }
    },
  },
  methods: {
    doConvert() {
      if (!this.localDataset) {
        this.errors.push('Please select a sequencing run')
      } else {
        // console.log('Selected Sequencing Run', this.localDataset)
        let _id = this.localDataset
        let options = this.currentOptions
        console.log('Conversion Form -> Options:', options)
        // if (this.wildcards) {
        //   options.push('wildcards ' + this.wildcards_values)
        // }
        this.$http
          .post(`${this.$config.api}/conversions/${_id}`, {
            samplesheet: this.samplesheet,
            pipeline: this.pipeline,
            options: options,
          })
          .then((response) => {
            this.$store.dispatch('snack', `Conversion request submitted`)
            this.samplesheet = ''
            this.$emit('close-dialog')
            // this.conversionDialog = false
            console.log(response.data)
          })
      }
    },
    getDatasets() {
      this.$http
        .get(`${this.$config.api}/datasets/validated`)
        .then((response) => {
          this.datasets = response.data
          console.log('ConversionForm -> getDatasets', response.data)
        })
    },
  },
  mounted() {
    // if we were passed a specific dataset, no need to look up all
    if (
      this.dataset &&
      Object.prototype.hasOwnProperty.call(this.dataset, '_id')
    ) {
      this.localDataset = this.dataset._id
    } else {
      this.getDatasets()
    }
  },
}
</script>
