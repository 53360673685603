<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <highcharts :options="pieChartOptions"></highcharts>
        </v-col>
        <v-col>
          <highcharts :options="requestsChartOptions"></highcharts>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <disk-usage></disk-usage>
        </v-col>
        <v-col cols="6">
          <users-chart></users-chart>
        </v-col>
      </v-row>
    </v-container>

    <items-count :items="selected"></items-count>
    <v-data-table
      :items="events"
      :headers="headers"
      class="elevation-4"
      disable-pagination
      hide-default-footer
      sort-by="updatedAt"
      :sort-desc="true"
      item-key="_id"
      :search="search"
      @current-items="updateSelected"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          outlined
          clearable
          prepend-inner-icon="mdi-magnify"
          class="mx-4 mt-4"
        ></v-text-field>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment('YYYY-MM-DD HH:mm') }}
      </template>

      <template v-slot:item.action="{ item }">
        {{ cleanAction(item.action) }}
      </template>

      <template v-slot:item.route="{ item }">
        <router-link :to="{ path: item.route }">
          {{ item.route }}
        </router-link>
      </template>

      <template v-slot:item.size="{ item }">
        <v-layout v-if="!isEmpty && item.size" justify-end>
          <file-size :bytes="item.size"></file-size>
        </v-layout>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import DiskUsage from '../components/DiskUsage'
import FileSize from '@/components/FileSize'
import ItemsCount from '@/components/ItemsCount'
import UsersChart from '@/components/UsersChart'

export default {
  name: 'Stats',
  components: {
    highcharts: Chart,
    DiskUsage,
    FileSize,
    ItemsCount,
    UsersChart,
  },
  computed: {},

  data() {
    return {
      events: [],
      selected: [],
      search: null,
      headers: [
        { value: 'createdAt', text: 'Date' },
        { value: 'user.email', text: 'User' },
        { value: 'action', text: 'Action' },
        { value: 'details', text: 'Details' },
        { value: 'route', text: 'Source Route' },
        { value: 'dataproduct', text: 'Data Product' },
        // { value: 'dataproduct.size', text: 'Size' },
        // { value: 'dataset', text: 'Sequencing Run' },
        // { value: 'project', text: 'Project' },
        // { value: 'worker', text: 'Worker' },
      ],

      titles: {},
      pieChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: 'Data Access Methods',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: 'Total',
            colorByPoint: true,
            data: [],
          },
        ],
      },

      pointStart: 1,
      requestsChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          // did not seem to have any effect
          // displayErrors: true,
          zoomType: 'xy',
        },
        title: {
          text: 'Requests Per Day',
        },
        xAxis: {
          type: 'datetime',
        },
        series: [
          {
            name: 'Number of Requests',
            // colorByPoint: true,
            type: 'line',
            pointInterval: 24 * 3600 * 1000, // one day
            data: [],
          },
        ],
      },
    }
  },

  methods: {
    getEvents() {
      this.$http.get(`${this.$config.api}/events/all`).then((response) => {
        this.events = response.data
        // console.log('Stats -> Events', this.events)
      })
    },
    tallyActions() {
      this.titles['Email Share'] = 0
      this.titles['wget'] = 0
      this.titles['Direct Download'] = 0
      this.titles['Data Capacitor'] = 0
      for (var event of this.selected) {
        // console.log('Checking event', event)
        if (event.action == 'Download Share Email') {
          this.titles['Email Share'] += 1
        } else if (event.action == 'Download Copy') {
          if (event.details == 'wget command') {
            this.titles['wget'] += 1
          } else if (event.details == 'Direct Download') {
            this.titles['Direct Download'] += 1
          } else if (event.details == 'DC Location') {
            this.titles['Data Capacitor'] += 1
          }
        }
      }

      // console.log('Titles', this.titles)

      // reset every time we call
      this.pieChartOptions.series[0].data = []
      for (var t in this.titles) {
        this.pieChartOptions.series[0].data.push({
          name: t,
          y: this.titles[t],
        })
      }
    },
    eventsPerDay() {
      let lookup = {}
      let day = ''
      let ts = 0
      for (var event of this.selected) {
        // console.log('Checking event', event)
        day = event.createdAt.substring(0, 10)
        ts = Date.parse(day)
        // console.log('Timestamp: ', ts)
        if (Object.prototype.hasOwnProperty.call(lookup, ts)) {
          lookup[ts] += 1
        } else {
          lookup[ts] = 1
        }
      }

      // console.log('Lookup', lookup)
      let dayInMS = 24 * 3600 * 1000
      let series = []
      // these should be in milliseconds
      let keys = Object.keys(lookup).sort()
      // console.log('lookup keys', keys)
      this.pointStart = Number(keys[0])
      let dates = this.range(keys[0], keys[keys.length - 1], dayInMS)
      // console.log('dates range', dates)
      for (ts of dates) {
        // console.log('checking for', ts)
        if (Object.prototype.hasOwnProperty.call(lookup, ts)) {
          // console.log('Matched', ts, lookup[ts])
          // first attempt ...
          //  [ [x, y], ... ] type series
          // series.push([String(ts), lookup[ts]])
          series.push(lookup[ts])
        } else {
          // console.log('No matching (0)', ts)
          series.push(0)
          // series.push([String(ts), 0])
        }
      }
      // console.log('Series', series)
      return series
    },
    range(start = 0, end = undefined, step = 1) {
      // console.log('range called with', start, end, step)
      start = parseInt(start)
      end = parseInt(end)
      step = parseInt(step)

      let result = [start]
      while (start < end) {
        start += step
        if (start <= end) {
          result.push(start)
        }
      }
      return result
    },
    updateSelected(items) {
      // console.log('Updating data table selection to be', items)
      this.selected = items
      this.requestsChartOptions.series[0].data = this.eventsPerDay()
      this.requestsChartOptions.series[0].pointStart = this.pointStart
      // console.log(this.requestsChartOptions.series)
      this.tallyActions()
    },
    cleanAction(item) {
      if (item === 'Download Copy') {
        return 'Copy'
      } else if (item === 'Download Share Email') {
        return 'Share Email'
      }
    },
  },
  mounted() {
    console.log('Stats page mounted call')
    this.getEvents()
  },
}
</script>
