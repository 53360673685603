<template>
  <div>
    <highcharts :options="diskChartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
  name: 'DiskChart',
  components: {
    highcharts: Chart,
  },

  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    dataproducts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      items: [],
      pointStart: null,
      diskChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          // did not seem to have any effect
          // displayErrors: true,
          zoomType: 'xy',
          height: '200px',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          labels: {
            format: '{text} TB',
          },
        },
        tooltip: {
          pointFormat: 'SDA Utilization: {point.y:.2f} TB',
        },
        series: [
          {
            name: 'SDA Utilization',
            // colorByPoint: true,
            type: 'line',
            pointInterval: 24 * 3600 * 1000, // one day
            data: [],
          },
        ],
      },
    }
  },

  computed: {
    diskChartTooltips() {
      let _options = {
        formatter: function () {
          function gigabytes(amount) {
            if (typeof amount !== 'number' || isNaN(amount)) {
              throw new TypeError('Expected a number')
            }

            var marker = 1024 // Change to 1000 if required
            var decimal = 2
            var gigaBytes = marker * marker * marker // One GB is 1024 MB
            return (amount / gigaBytes).toFixed(decimal) + ' GB'
          }

          return (
            '<b>' +
            gigabytes(this.y) +
            '</b>: ' +
            this.$moment(this.x).format('YYYY-MM-DD')
          )
        },
      }
      // console.log('Options:', _options)
      return _options
    },
  },

  methods: {
    terabytes(amount) {
      if (typeof amount !== 'number' || isNaN(amount)) {
        throw new TypeError('Expected a number')
      }

      var marker = 1024 // Change to 1000 if required
      var teraBytes = marker * marker * marker * marker // One TB is 1024 GB
      // return (amount / teraBytes).toFixed(decimal) + ' TB'
      return amount / teraBytes
    },
    diskPerDay() {
      let lookup = {}
      let day = ''
      let ts = 0
      // console.log('Tally storage usage per day')
      // collect totals for each day
      for (var collection of [this.datasets, this.dataproducts]) {
        // console.log('looking at collection', collection)
        for (var item of collection) {
          // console.log('Checking item', item)
          day = item.createdAt.substring(0, 10)
          ts = Date.parse(day)
          // console.log('Timestamp: ', ts)
          if (Object.prototype.hasOwnProperty.call(lookup, ts)) {
            lookup[ts] += item.size
          } else {
            lookup[ts] = item.size
          }
        }
      }
      // console.log('Lookup', lookup)

      let dayInMS = 24 * 3600 * 1000
      let series = []
      // these should be in milliseconds
      let keys = Object.keys(lookup).sort()
      // get rid of the first one -- not an accurate created at
      // keys = keys.slice(1)
      // console.log('lookup keys', keys)
      this.pointStart = Number(keys[0])

      let dates = this.range(keys[0], keys[keys.length - 1], dayInMS)
      // console.log('dates range', dates)

      // keep track of a running total
      let tally = 0
      for (ts of dates) {
        // console.log('checking for', ts)
        if (Object.prototype.hasOwnProperty.call(lookup, ts)) {
          tally += lookup[ts]
        }
        let tb = this.terabytes(tally)
        // prettyBytes function is being used in DiskUsage
        // but returns string values
        // we need numbers here
        // let tb = this.$options.filters.prettyBytes(tally)
        // console.log('Current size', tb)
        // confirmed values are the same
        series.push(tb)
        // series.push(tally)
      }
      // console.log('disk Chart Series', series)
      return series
    },
    range(start = 0, end = undefined, step = 1) {
      // console.log('range called with', start, end, step)
      start = parseInt(start)
      end = parseInt(end)
      step = parseInt(step)

      let result = [start]
      while (start < end) {
        start += step
        if (start <= end) {
          result.push(start)
        }
      }
      return result
    },
  },
  mounted() {
    // console.log('diskChartOptions', this.diskChartOptions)
  },
  watch: {
    // dataproducts should change last, (vs. datasets)
    // so triggering on that
    dataproducts: function () {
      // console.log('dataproduct changed', newVal)
      this.diskChartOptions.series[0].data = this.diskPerDay()
      this.diskChartOptions.series[0].pointStart = this.pointStart
      // this.diskChartOptions.tooltips = this.diskChartTooltips
    },
  },
}
</script>

<style></style>
