<template>
    <div>
        <UserList></UserList>
    </div>
</template>

<script>

  import UserList from "@/components/admin/UserList.vue";

  export default {
    name: 'Users',
    components: {UserList},
    data() {
      return {
        
      }
    }
  }
</script>
