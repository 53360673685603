<template>
  <div>
    <v-dialog v-model="download_dialog" max-width="600px">
      <download-instructions :active_dl="active_dl" @close-dialog="download_dialog = false"></download-instructions>
    </v-dialog>

    <v-dialog v-model="share_dialog" max-width="600px">
      <download-share :active_dl="active_dl" @close-dialog="share_dialog = false"></download-share>
    </v-dialog>

    <v-dialog v-model="stage_dialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <v-icon class="mx-2">mdi-lan-pending</v-icon> Stage Data Product
        </v-card-title>
        <v-card-text class="mt-2">
          <div class="text--primary">
            Click "Start" to request the data product to be loaded from the tape
            archive to disk
          </div>
          <v-checkbox v-model="notify" label="Notify by email when staging job has completed?"></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="stage_dialog = false">
            Close
          </v-btn>

          <v-btn color="success" text @click="requestProduct"> Start </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <items-count v-if="showcounts" :items="selected"></items-count>

    <v-data-table :items="dataproducts" :headers="headers" sort-by="createdAt" :sort-desc="true" dense hide-default-footer
      disable-pagination :single-expand="true" :show-expand="expandable" item-key="_id" :expanded.sync="expanded"
      :search="search" class="elevation-4" @current-items="updateSummary">
      <!--            <template v-slot:item.location="{item}">-->
      <!--                <v-icon color="blue lighten-2" v-if="item.location==='disk'">mdi-harddisk</v-icon>-->
      <!--                <v-icon color="orange darken-2" v-if="item.location==='archive'">mdi-archive</v-icon>-->
      <!--            </template>-->

      <template v-if="searchable" v-slot:top>
        <v-text-field v-model="search" label="Search" outlined prepend-inner-icon="mdi-magnify" clearable
          class="mx-4 mt-4"></v-text-field>
      </template>

      <template v-slot:item.details="{ item }">
        <div v-if="item.dataset">
          <v-tooltip v-if="item.staged" top>
            <template v-slot:activator="{ on }">
              <router-link :to="{
                name: 'RunDetails',
                params: { name: item.dataset.name, product: item._id },
              }">
                <v-icon v-on="on" small class="mr-2">mdi-details</v-icon>
              </router-link>
            </template>
            <span> Show details for dataset </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.method="{ item }">
        <div v-if="item.conversion">
          <router-link :to="{
            name: 'Conversions',
          }" class="mb-4 mr-4 grey--text text--darken-2">
            <v-icon small class="mr-2">mdi-transition</v-icon>
            Conversion
          </router-link>

          <v-tooltip v-if="item.qc_url && item.qc_url !== ''" top>
            <template v-slot:activator="{ on }">
              <v-btn class="my-2" v-on="on" small :href="item.qc_url" target="_blank">
                <v-icon color="info"> mdi-book-check </v-icon>
              </v-btn>
            </template>
            <span> MultiQC report available (opens in new tab) </span>
          </v-tooltip>
        </div>
        <div v-else-if="item.upload">
          <router-link :to="{
            name: 'DataUploader',
          }" class="mb-4 mr-4 grey--text text--darken-2">
            <v-icon small class="mr-2">mdi-folder-upload</v-icon>
            Upload
          </router-link>
          <v-tooltip v-if="item.qc_url && item.qc_url !== ''" top>
            <template v-slot:activator="{ on }">
              <v-btn class="my-2" v-on="on" small :href="item.qc_url" target="_blank">
                <v-icon color="info"> mdi-book-check </v-icon>
              </v-btn>
            </template>
            <span> MultiQC report available (opens in new tab) </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.dataset="{ item }">
        <div v-if="item.dataset">
          <router-link :to="{
            name: 'RunDetails',
            params: { name: item.dataset.name, product: item._id },
          }">
            {{ item.dataset.name }}
          </router-link>
        </div>
      </template>

      <template v-slot:item.project="{ item }">
        <div v-if="item.project">
          <router-link :to="{
            name: 'ProjectDetails',
            params: { name: item.project.name },
          }">
            {{ item.project.name }}
          </router-link>
        </div>
      </template>

      <template v-slot:item.files="{ item }">
        <div v-if="!isEmpty && item.files">
          {{ item.files.length }}
        </div>
      </template>

      <template v-slot:item.size="{ item }">
        <v-layout v-if="!isEmpty && item.size" justify-end>
          <file-size :bytes="item.size"></file-size>
        </v-layout>
      </template>

      <template v-slot:item.groups="{ item }">
        <v-chip small color="primary" v-for="g in item.groups" :key="g._id">
          {{ g.name }}
        </v-chip>
      </template>

      <template v-slot:item.download="{ item }">
        <v-tooltip v-if="item.staged" top>
          <template v-slot:activator="{ on }">
            <v-btn class="my-2" v-on="on" @click.stop="
              set_active_dl(item)
                                                          download_dialog = true
                                                        ">
              <v-icon color="info"> mdi-cloud-download </v-icon>
            </v-btn>
          </template>
          <span>
            Links and descriptions for how to download this data product
          </span>
        </v-tooltip>

        <v-tooltip v-if="!item.staged" top>
          <template v-slot:activator="{ on }">
            <v-btn disabled class="my-2" v-on="on">
              <v-icon color="grey"> mdi-cloud-download </v-icon>
            </v-btn>
          </template>
          <span>
            This data product must be restored from tape before it can be
            downloaded
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.explore="{ item }">
        <SessionDialog :tracks="item.files" :dataproduct="item._id"></SessionDialog>
      </template>

      <template v-slot:item.email="{ item }">
        <v-tooltip v-if="item.staged" top>
          <template v-slot:activator="{ on }">
            <v-btn class="my-2" v-on="on" @click.stop="
              set_active_dl(item)
                                                          share_dialog = true
                                                        ">
              <v-icon color="info"> mdi-email </v-icon>
            </v-btn>
          </template>
          <span> Send an email with download links to collaborators </span>
        </v-tooltip>

        <v-tooltip v-if="!item.staged" top>
          <template v-slot:activator="{ on }">
            <v-btn disabled class="my-2" v-on="on">
              <v-icon color="grey"> mdi-email </v-icon>
            </v-btn>
          </template>
          <span>
            This data product must be restored from tape before it can be shared
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.stage="{ item }">
        <v-tooltip v-if="!item.staged && !item.requested && !item.disable_archive" top>
          <template v-slot:activator="{ on }">
            <v-btn class="my-2" v-on="on" @click.stop="
              setActive(item)
                                                          stage_dialog = true
                                                        ">
              <v-icon color="info"> mdi-lan-pending </v-icon>
            </v-btn>
          </template>
          <span> Restore this data product from tape </span>
        </v-tooltip>

        <v-tooltip v-if="!item.staged && item.requested" top>
          <template v-slot:activator="{ on }">
            <v-btn class="my-2" v-on="on">
              <v-icon color="orange lighten-1"> mdi-lan-pending </v-icon>
            </v-btn>
          </template>
          <span> This product is being restored from tape </span>
        </v-tooltip>

        <v-tooltip v-if="!item.staged && item.disable_archive" top>
          <template v-slot:activator="{ on }">
            <v-btn class="my-2" v-on="on">
              <v-icon color="grey"> mdi-lan-pending </v-icon>
            </v-btn>
          </template>
          <span> This data product was not archived to the SDA. Please contact the system operator to request a new
            conversion </span>
        </v-tooltip>

        <v-tooltip v-if="item.staged" top>
          <template v-slot:activator="{ on }">
            <v-btn disabled class="my-2" v-on="on">
              <v-icon color="grey"> mdi-lan-pending </v-icon>
            </v-btn>
          </template>
          <span> This data product is currently available on disk </span>
        </v-tooltip>
      </template>

      <template v-slot:item.users="{ item }">
        <v-chip small dense color="secondary" v-for="u in item.users" :key="u._id">
          {{ u.fullname }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="!isEmpty" small dense color="red" dark @click="setActive(item)">
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <data-product-details v-on="$listeners" :dataproduct="item"></data-product-details>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FileSize from './FileSize.vue'
import ItemsCount from '@/components/ItemsCount'
import DataProductDetails from './DataProductDetails.vue'
import DownloadInstructions from './DownloadInstructions.vue'
import DownloadShare from './DownloadShare.vue'
import SessionDialog from '@/components/SessionDialog'

export default {
  components: {
    FileSize,
    ItemsCount,
    DataProductDetails,
    DownloadInstructions,
    DownloadShare,
    SessionDialog,
  },
  props: {
    dataproducts: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showcounts: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isEmpty() {
      return (
        this.dataproducts.length === 0 ||
        (this.dataproducts.length === 1 &&
          this.dataproducts[0].name === 'No data products available')
      )
    },
  },

  data() {
    return {
      expanded: [],
      // for dynamic counts
      selected: [],

      // for automatically expanding a row
      selectedProduct: null,

      search: '',

      groups: [],
      users: [],
      active_dataproduct: {
        name: '',
      },
      active_group: {
        name: '',
      },
      download_dialog: false,
      share_dialog: false,
      active_dl: {
        name: '',
        uuid: '',
        _id: '',
        files: {
          size: 0,
        },
      },
      stage_dialog: false,
      notify: false,
    }
  },
  methods: {
    setActive(dp) {
      this.$nextTick(() => {
        this.active_dataproduct = dp
        // this.removeDialog = true
        console.log('Selected Dataproduct: ', this.active_dataproduct)
      })
    },
    set_active_dl: function (item) {
      console.log('set_active_dl -> ', item)
      this.active_dl.name = item.name
      this.active_dl.uuid = item.downloads.uuid
      this.active_dl._id = item.downloads._id
      this.active_dl.files = item
    },

    requestProduct() {
      this.stage_dialog = false
      console.log('requestProduct called')
      let data = {
        _id: this.active_dataproduct._id,
        notify: this.notify,
      }

      this.$http.post(`${this.$config.api}/dataproducts/request`, data).then(
        (res) => {
          console.log(res.data)
          this.$store.dispatch('snack', `Data product requested`)
          // signal to parent to poll for updates to data products
          this.$emit('start-poll')
        },
        (err) => {
          console.log('Error contacting API')
          console.dir(err)
        }
      )
    },
    updateSummary(items) {
      this.selected = items
    },

    // TODO: way to abstract into a separate component?
    // duplicated in ui/src/views/DataProducts.vue
    checkQC() {
      this.dataproducts.forEach((dp) => {
        this.$set(dp, 'qc_url', '')
        console.log(dp.upload, dp.file_type);
        if (dp.conversion || (dp.upload && dp.file_type == 'fastq')) {
          let id = dp.conversion?._id || dp.conversion;
          if (id == undefined) id = dp.upload;
          // console.log(`Checking on dp ${dp.conversion}, ${dp.name}`);
          let qc_url = `${this.$config.api}/qc/${id}/${dp.name}/multiqc_report.html`
          this.$http.head(qc_url).then((res) => {
            console.log(res.status)
            this.$set(dp, 'qc_url', qc_url)
          })
        }
      })
    },
  },
  mounted() {
    this.selectedProduct = this.$route.params.product
    this.dataproducts.forEach((product) => {
      // console.log(
      //   'checking for match',
      //   product,
      //   product._id,
      //   this.selectedProduct
      // )
      if (this.selectedProduct && product._id === this.selectedProduct) {
        this.expanded.push(product)
      }
    })
    this.checkQC()
  },
}
</script>
