<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" @click.stop="handleClick" class="mb-4 mr-4">
        <v-icon class="mr-2">mdi-account-edit</v-icon>
        Edit Permissions
      </v-btn>
    </template>
    <span> Change user and group access for this project </span>
  </v-tooltip>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit('permissions-button')
    },
  },
}
</script>
