<template>
    <div class="logview my-1" ref="container" @scroll.passive="onScroll">
        <v-btn small color="info" @click="scrollToBottom">Scroll to Bottom <v-icon>mdi-chevron-triple-down</v-icon></v-btn>
        <pre v-for="(l, idx) in logfile" :key="idx">{{ l }}</pre>
        <span ref="bottom_holder" id="bottom_holder"></span>
    </div>
</template>

<script>
    export default {
      name: 'LogViewer',
      props: {
        name: String,
        status: String
      },
      data() {
        return {
          logfile: [],
          loglength: 0,
          destroyed: false,
          scrolledToBottom: false
        }
      },
      methods: {
        loadLog() {
          let self = this
          this.$http
            .get(`${this.$config.api}/conversions/log/${self.name}/${self.loglength}`)
            .then((response) => {
              console.log(response.data)
              let atBottom = false;
              if(self.scrolledToBottom) atBottom = true;
              self.logfile = self.logfile.concat(response.data.logfile);
              self.loglength = response.data.lines;
              console.log(self.status);

              if(atBottom) self.scrollToBottom();
              if(self.status !== 'completed' && !self.destroyed) {
                setTimeout(() => {
                  console.log("REFRESHING LOG");
                  self.loadLog();
                }, 10000)
              }
            })
        },

        scrollToBottom() {
          const el = this.$el.querySelector("#bottom_holder");
          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
          this.scrolledToBottom = true;
        },

        //from here: https://stackoverflow.com/questions/59603315/how-to-detect-when-a-user-scrolls-to-the-bottom-of-a-div-vue
        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
          console.log("SCROLLING");
          this.scrolledToBottom  = scrollTop + clientHeight >= scrollHeight;
          console.log(this.scrolledToBottom);
        }
      },
      mounted() {
        this.loadLog();
        this.destroyed = false;
      },
      beforeDestroy() {
        console.log("DESTROYING");
        this.destroyed = true;
      }
    }
</script>

<style>
    pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }

    .logview {
        max-height: 500px;
        max-width: 1100px;
        overflow: auto;
        overscroll-behavior: contain;
    }
</style>
