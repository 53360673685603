var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-4",attrs:{"items":_vm.cleans,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","sort-by":"updatedAt","sort-desc":true,"single-expand":true,"item-key":"_id","show-expand":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'YYYY-MM-DD'))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'YYYY-MM-DD'))+" ")]}},{key:"item.datasets",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.datasets.length)+" ")]}},{key:"item.dataproducts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataproducts.length)+" ")]}},{key:"item.tmp_dirs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tmp_dirs.length)+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-end":""}},[_c('file-size',{attrs:{"bytes":item.size}})],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.datasets.length)?_c('div',[_c('h3',[_vm._v("Sequencing Runs")]),_vm._l((item.datasets),function(f,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(f.name)+" ")])})],2):_vm._e(),(item.dataproducts.length)?_c('div',[_c('h3',[_vm._v("Dataproducts")]),_vm._l((item.dataproducts),function(f,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(f)+" ")])})],2):_vm._e(),(item.tmp_dirs.length)?_c('div',[_c('h3',[_vm._v("Temp Directories")]),_vm._l((item.tmp_dirs),function(f,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(f)+" ")])})],2):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }