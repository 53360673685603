<template>
  <div>
    <v-dialog v-model="deleteDialog" max-width="450">
      <v-card>
        <v-card-title class="headline">
          Delete {{ dataset.name }}
        </v-card-title>

        <v-card-text>
          Delete the record associated with this dataset from the database. This
          action does not remove any files related to the dataset. Are you sure
          you want to remove the record for dataset
          {{ dataset.name }} ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Disagree
          </v-btn>

          <v-btn color="green darken-1" text @click="deleteItem"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="conversionDialog" max-width="600px">
      <conversion-form :dataset="dataset" @close-dialog="conversionDialog = false"></conversion-form>
    </v-dialog>

    <v-dialog v-model="uploadDialog" max-width="600px">
      <upload-form :dataset="dataset" @close-dialog="uploadDialog = false"></upload-form>
    </v-dialog>

    <div class="text-h4 font-weight-light">
      <v-icon large class="pb-2 ml-1">{{ icon }}</v-icon>
      <router-link :to="{ name: 'Runs' }">Sequencing Run</router-link>
      : {{ datasetName }}
    </div>
    <v-divider class="mb-3"></v-divider>

    <v-container>
      <v-row>
        <v-col>
          <v-container>
            <h2>Associated Data Products</h2>
            <v-row>
              <v-col v-if="dataproducts.length">
                <data-product-list :dataproducts="dataproducts" :headers="headers" :expandable="expandable"
                  v-on="$listeners" @reload-product="reloadAllProducts" class="mt-4"></data-product-list>
              </v-col>
              <v-col v-if="!dataproducts.length">
                No data products available
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card v-if="dataset" class="elevate-3">
            <v-container>
              <h2>General info</h2>
              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Started on</div>
                </v-col>
                <v-col>{{ dataset['takenAt'] | moment('YYYY-MM-DD') }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Registered on</div>
                </v-col>
                <v-col>{{ dataset['createdAt'] | moment('YYYY-MM-DD') }}</v-col>
              </v-row>

              <v-row v-if="dataset && dataset['paths']">
                <v-col cols="4" justify="end">
                  <div class="text-right">Registration source node</div>
                </v-col>
                <v-col cols="8">{{ dataset['source_node'] }}</v-col>
              </v-row>

              <v-row v-if="dataset && dataset['paths']">
                <v-col cols="4" justify="end">
                  <div class="text-right">Registration source path</div>
                </v-col>
                <v-col cols="8">{{ dataset['paths']['origin'] }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Last updated</div>
                </v-col>
                <v-col>{{ dataset['updatedAt'] | moment('YYYY-MM-DD') }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Total Size</div>
                </v-col>
                <v-col>{{ parseInt(dataset.size) | gigaBytes }} </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Total Files</div>
                </v-col>
                <v-col>{{ dataset['files'] }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Total CBCLs</div>
                </v-col>
                <v-col>{{ dataset['cbcls'] }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Total Directories</div>
                </v-col>
                <v-col>{{ dataset['directories'] }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="4" justify="end">
                  <div class="text-right">Description</div>
                </v-col>
                <v-col>
                  <v-textarea v-model="dataset.description" @input="updateDataset" placeholder="Add a description...">
                    {{ dataset['description'] }}
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card class="elevate-3 mb-4">
            <v-container>
              <h2>Actions</h2>

              <v-row v-if="dataset">
                <v-col v-if="!dataset['staged']">
                  <div>
                    Additional actions (conversions, uploads, downloads) require
                    the dataset on disk
                  </div>
                </v-col>
                <v-col v-if="dataset['staged']">
                  <conversion-button @conversion-button="conversionDialog = true"></conversion-button>

                  <upload-button @upload-button="uploadDialog = true"></upload-button>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-card class="elevate-3 mb-4">
            <v-container>
              <h2 v-if="!dataset['archived']">Archive</h2>
              <v-row v-if="!dataset['archived']">
                <v-col cols="12"> Pending </v-col>
              </v-row>

              <h2 v-if="dataset['archived']">Archived</h2>
              <v-row v-if="dataset['archived']">
                <v-col cols="2">
                  <v-icon class="mx-1" color="green"> mdi-check </v-icon>
                </v-col>

                <v-col cols="9">{{ dataset['paths']['archive'] }}</v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-card class="elevate-3 mb-4">
            <v-container>
              <h2>Staged for processing</h2>
              <v-row v-if="!filesExist || stagingInProgress">
                <v-col>{{
                  stagingInProgress ? 'Staging in Progress' : 'Tape only'
                }}</v-col>
                <v-col>
                  <v-btn @click="restoreFromTape" class="mb-4" :disabled="stagingInProgress || disableRestoreFromTapeButton
                    ">
                    <v-icon class="mr-2">mdi-package-down</v-icon>
                    restore from tape
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!filesExist && !stagingInProgress">
                <v-col v-if="expired">
                  Sequencing Runs older than 30 days are removed from disk to
                  minimize resource utilization
                </v-col>
              </v-row>
              <v-row v-if="!filesExist || stagingInProgress">
                <v-col cols="12">{{ dataset['paths']['staged'] }}</v-col>
              </v-row>
              <v-row v-if="stagingInProgress">
                <v-col cols="12">
                  <div v-if="dataset.taken.status === 'working' &&
                    dataset.taken.service === 'stagings' &&
                    dataset.taken.progress === null
                    ">
                    <div class="mt-3 text-caption">
                      <b>
                        Requesting job on the SDA. Progress will update when the
                        job begins
                      </b>
                    </div>
                  </div>

                  <WorkerProgess :worker="dataset.taken"></WorkerProgess>
                </v-col>
              </v-row>
              <v-row v-if="filesExist && dataset['staged']">
                <v-col cols="2">
                  <v-icon class="mx-1" color="green"> mdi-check </v-icon>
                </v-col>

                <v-col cols="9">{{ dataset['paths']['staged'] }}</v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-card v-if="hasEvents" class="elevate-3 mb-4">
            <v-container>
              <h2>Data Management History</h2>
              <highcharts :options="historyChartOptions"></highcharts>

              <v-data-table :items="eventDurations" :headers="historyHeaders" sort-by="stamp" :sort-desc="true" dense
                hide-default-footer disable-pagination :single-expand="false" :show-expand="false" item-key="stamp">
                <template v-slot:item.icon="{ item }">
                  <v-icon left color="info">{{ w_m[item.name].icon }}</v-icon>
                </template>

                <template v-slot:item.name="{ item }">
                  {{ w_m[item.name].title }}
                </template>

                <template v-slot:item.start="{ item }">
                  {{ item.start | moment('YYYY-MM-DD HH:mm:ss') }}
                </template>

                <template v-slot:item.duration="{ item }">
                  {{ item.end | moment('from', item.start, true) }}
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col>
          <v-container>
            <h2>Deleted Data Products</h2>
            <v-row>
              <v-col v-if="deletedDataproducts.length">
                <data-product-list :dataproducts="deletedDataproducts" :headers="deletedHeaders"
                  :expandable="deletedExpandable" v-on="$listeners" @reload-product="reloadAllProducts"
                  class="mt-4"></data-product-list>
              </v-col>
              <v-col v-if="!deletedDataproducts.length">
                No deleted data products available
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col>
          <v-container>
            <h2>Maintenance Actions</h2>
            <v-row>
              <v-col>
                <div v-if="dataset.taken">
                  <v-btn v-show="dataset.taken" class="mb-4 mr-4" @click.stop="releaseWorker(dataset)">
                    <v-icon class="mr-2" color="orange lighten-2">
                      mdi-lock-open-check
                    </v-icon>
                    Release Worker
                  </v-btn>
                  Assigned worker: {{ dataset.taken.name }}<br />
                  Command: {{ dataset.taken.command }}<br />
                  Status: {{ dataset.taken.status }}<br />
                </div>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mb-4 mr-4" @click.stop="
                      active_item = dataset
                    deleteDialog = true
                      " v-on="on">
                      <v-icon class="mr-2" color="red lighten-2">
                        mdi-delete
                      </v-icon>
                      Delete Sequencing Run
                    </v-btn>
                  </template>
                  <span>
                    Delete the record associated with this sequencing run from
                    the database. This action does not remove any data files
                    related to the sequencing run.
                  </span>
                </v-tooltip>

                <!--                <v-icon class="mr-2" color="red darken-2">mdi-delete</v-icon>-->
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ConversionForm from '@/components/ConversionForm'
import ConversionButton from '@/components/ConversionButton'
import UploadForm from '@/components/UploadForm'
import UploadButton from '@/components/UploadButton'
import DataProductList from '@/components/DataProductList'
import WorkerProgess from '@/components/WorkerProgress'

import { Chart } from 'highcharts-vue'
import debounce from 'debounce'

export default {
  name: 'RunDetails',

  // FYI: layouts are defined in router/index.js
  layout: 'datatables',
  components: {
    ConversionForm,
    ConversionButton,
    UploadForm,
    UploadButton,
    DataProductList,
    highcharts: Chart,
    WorkerProgess,

  },
  computed: {
    expired() {
      // 30 days ago
      let timestamp = new Date().getTime() - 30 * 24 * 60 * 60 * 1000
      if (this.dataset) {
        let created = Date.parse(this.dataset.createdAt)
        if (created < timestamp) {
          // console.log('created at', created, 'less than', timestamp)
          return true
        } else {
          // console.log('created at', created, 'greater than', timestamp)
          return false
        }
      } else {
        // console.log('Expired: No dataset')
        return false
      }
    },
    hasEvents() {
      if (
        Object.prototype.hasOwnProperty.call(this.dataset, 'events') &&
        this.dataset['events'].length
      ) {
        return true
      } else {
        return false
      }
    },
    eventDurations() {
      if (!this.hasEvents) return []

      let sorted_events = {}

      // console.log('checking dataset events:', this.dataset.events)
      for (let event of this.dataset.events) {
        let name = event.description.split(' - ')[0]
        let stamp = Date.parse(event.stamp)
        if (!(name in sorted_events)) {
          sorted_events[name] = {
            name: name,
            start: stamp,
            end: null,
            duration: null,
          }
        } else {
          if (sorted_events[name].start > stamp) {
            sorted_events[name].end = sorted_events[name].start
            sorted_events[name].start = stamp
          } else {
            sorted_events[name].end = stamp
          }

          sorted_events[name].duration =
            (sorted_events[name].end - sorted_events[name].start) / 1000
        }
      }

      console.log(sorted_events)
      return Object.values(sorted_events)
    },
    historyChartOptions() {
      let seriesData = []

      this.eventDurations.forEach((ev) => {
        let step = {
          name: ev.name,
          data: [ev.duration / 60],
          visible: true,
        }
        if (step.name === 'registration') {
          step.visible = false
        }
        seriesData.push(step)
      })

      seriesData.reverse()

      return {
        chart: {
          type: 'bar',
          height: '200px',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: 'Processing time in minutes',
        },
        xAxis: {
          categories: [null],
        },
        yAxis: {
          min: 0,
          title: {
            text: null,
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        series: seriesData,
      }
    },
    stagingInProgress() {
      return this.dataset['staged'] === false && this.dataset['taken'] != null
    },
  },
  data() {
    return {
      datasetName: '',
      // aka navItem.action
      icon: 'mdi-dna',
      dataset: false,
      filesExist: true,
      dataproducts: false,
      active_item: '',

      deleteDialog: false,
      deletedDataproducts: [],

      conversionDialog: false,
      uploadDialog: false,
      w_m: {
        registration: { title: 'Registration', icon: 'mdi-clipboard-check' },
        archive: { title: 'Archiving', icon: 'mdi-package-up' },
        stage: { title: 'Staging', icon: 'mdi-package-down' },
        validate: { title: 'Validation', icon: 'mdi-file-check' },
        inspection: { title: 'Inspection', icon: 'mdi-barcode-scan' },
      },
      expandable: true,
      headers: [
        // {text: 'Status', value:'location'},
        { value: 'data-table-expand', text: '' },
        { text: 'Data Product', value: 'name' },
        { text: 'Files', value: 'files' },
        // { text: 'Groups', value: 'groups' },
        // { text: 'Users', value: 'users' },
        // { text: 'Edit', value: 'actions' },
        { text: 'Project', value: 'project' },
        { text: 'Method', value: 'method' },
        { text: 'Type', value: 'file_type' },
        // { text: 'Remove', value: 'actions' },
        { text: 'Size', value: 'size' },
      ],
      deletedExpandable: true,
      deletedHeaders: [
        { value: 'data-table-expand', text: '' },
        { text: 'Data Product', value: 'name' },
        { text: 'Files', value: 'files' },
        { text: 'Method', value: 'method' },
        { text: 'Type', value: 'file_type' },
        { text: 'Size', value: 'size' },
      ],
      historyHeaders: [
        { value: 'icon', text: '' },
        { value: 'name', text: 'Step' },
        { value: 'start', text: 'Start (UTC)' },
        { value: 'duration', text: 'Duration' },
      ],
      interval: null,
      disableRestoreFromTapeButton: false,
    }
  },
  mounted() {
    console.log('Route: ', this.$route)
    this.datasetName = this.$route.params.name
    this.getDataset()

    this.interval = setInterval(
      function () {
        console.log(
          `filesExist: ${this.filesExist}, stagingInProgress: ${this.stagingInProgress}, disableRestoreFromTapeButton: ${this.disableRestoreFromTapeButton}`
        )
        if (this.stagingInProgress || this.disableRestoreFromTapeButton) {
          // when the staging is in progress
          // or "Restore From Tape" button was clicked and the process was initiated
          // make GET /datasets/checkFiles/:id and GET /datasets/:name
          // These call updated filesExist and dataset state (staged and taken) that control
          // visibility (show / hide), enable / disable the "Restore from Tape", and display progress
          if (this.filesExist && !this.stagingInProgress) {
            // staging is complete, stop polling API
            if (this.interval != null) {
              clearInterval(this.interval)
            }
          } else {
            this.checkFileStatus()
            this.getDatasetAndUpdateState()
          }

          // this.getStagingWorker()
        }
        // else {
        //   if (this.interval != null) {
        //     clearInterval(this.interval)
        //   }
        // }
      }.bind(this),
      5000
    )
  },

  beforeDestroy: function () {
    if (this.interval != null) {
      clearInterval(this.interval)
    }
  },

  methods: {
    getDatasetAndUpdateState() {
      this.$http
        .get(`${this.$config.api}/datasets/${this.datasetName}`)
        .then((response) => {
          this.dataset = response.data
        })
        .catch((response) => console.log('getDataset error: ', response))
    },

    getDataset() {
      this.$http
        .get(`${this.$config.api}/datasets/${this.datasetName}`)
        .then((response) => {
          this.dataset = response.data
          console.log('getDataset result: ', response.data)
          this.getDataProducts()
          this.checkFileStatus()
          this.getDeletedDataProducts()
        })
        .catch((response) => console.log('getDataset error: ', response))
    },
    checkFileStatus() {
      this.$http
        .get(`${this.$config.api}/datasets/checkFiles/${this.dataset._id}`)
        .then((response) => {
          this.filesExist = response.data.filesExist
          // console.log('checkFileStatus result: ', response.data)
        })
        .catch((response) => console.log('checkFileStatus error: ', response))
    },
    getDataProducts() {
      this.$http
        .get(`${this.$config.api}/dataproducts/${this.dataset._id}`)
        .then((response) => {
          this.dataproducts = response.data
          if (!this.dataproducts.length) {
            this.expandable = false
            console.log('Expandable?', this.expandable)
            this.dataproducts.push({
              name: 'No data products available',
              method: '',
              file_type: '',
              groups: [],
              users: [],
              action: '',
              size: '',
              files: '',
            })
          }
          console.log('getDataProducts result: ', response.data)
        })
        .catch((response) => console.log('getDataProducts error: ', response))
    },

    getDeletedDataProducts() {
      this.$http
        .get(`${this.$config.api}/dataproducts/${this.dataset._id}/deleted`)
        .then((response) => {
          this.deletedDataproducts = response.data
          if (!this.deletedDataproducts.length) {
            // this.deletedExpandable = false
            this.deletedDataproducts.push({
              name: 'No data products deleted',
              method: '',
              file_type: '',
              groups: [],
              users: [],
              action: '',
              size: 0,
              files: '',
            })
          }
          console.log('getDeletedDataProducts result: ', response.data)
        })
        .catch((response) =>
          console.log('getDeletedDataProducts error: ', response)
        )
    },

    reloadAllProducts() {
      this.getDataProducts()
      this.getDeletedDataProducts()
    },

    updateDataset: debounce(function () {
      // console.log('Dataset Description updated', this.dataset.description)
      this.$http
        .put(`${this.$config.api}/datasets/update`, this.dataset)
        .then((response) => {
          // this.dataset = response.data
          console.log('updateDataset result: ', response.data)
        })
        .catch((response) => console.log('updateDataset error: ', response))
    }, 500),

    restoreFromTape() {
      // signal workers to do a restoration
      console.log(
        'Restore from tape requested. Restoring to',
        this.dataset['paths']['staged']
      )
      this.dataset.staged = false
      this.dataset.errored = null
      this.dataset.taken = null
      this.$http
        .put(`${this.$config.api}/datasets/update`, this.dataset)
        .then(() => {
          this.disableRestoreFromTapeButton = true
        })
        .catch((response) => console.log('updateDataset error: ', response))
    },

    releaseWorker(item) {
      this.$http
        .get(`${this.$config.api}/datasets/release/${item._id}`)
        .then((response) => {
          this.$store.dispatch('snack', `Worker released dataset`)
          item.taken = null
          console.log(response.data)
        })
    },

    deleteItem() {
      this.deleteDialog = false
      let self = this
      let item = this.active_item
      this.$http
        .delete(`${this.$config.api}/datasets/${item._id}`)
        .then((response) => {
          self.$store.dispatch('snack', `Dataset.deleted`)
          item.taken = null
          self.datasets = self.datasets.filter((ds) => {
            return ds !== item._id
          })

          console.log(response.data)
        })
    },
  },
}
</script>

<style scoped></style>
