<template>
  <div>
    <!-- <items-count :items="selected"></items-count> -->
    <v-text-field v-model="search" label="Search" @input="handleSearch" outlined prepend-inner-icon="mdi-magnify"
      clearable class="mx-4 mt-4"></v-text-field>

    <div class="mb-4 d-flex justify-space-between">
      <v-pagination v-model="page" :length="pages" total-visible="7" circle @input="getDatasets"
        class="ml-4"></v-pagination>
      <items-count :total="total" :key="total" class="mr-4"></items-count>
    </div>
    <v-data-table :items="datasets_filtered" :headers="headers" item-key="_id" class="elevation-4" disable-pagination
      hide-default-footer dense @current-items="updateSummary" sort-by="takenAt" :sort-desc="true" :loading="loading"
      :server-items-length="total" :options.sync="options" @update:options="handleUpdateOptions">
      <template v-slot:item.taken="{ item }">
        <v-progress-circular v-if="item.taken" indeterminate color="green"></v-progress-circular>
      </template>

      <template v-slot:item.name="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon color="blue" v-on="on" @click.stop="handleClick(item)" class="">
              <v-icon class="mr-2">mdi-shape-square-rounded-plus</v-icon>
            </v-btn>
          </template>
          <span> Open details in new tab </span>
        </v-tooltip>
        <router-link :to="{ name: 'RunDetails', params: { name: item.name } }">
          {{ item.name }}
        </router-link>
      </template>

      <template v-slot:item.takenAt="{ item }">
        {{ item.takenAt | moment('YYYY-MM-DD') }}
      </template>

      <template v-slot:item.archived="{ item }">
        <v-icon class="mx-1" color="green" v-if="item.archived">
          mdi-check
        </v-icon>

        <v-icon class="mx-1" color="gray lighten-5" v-if="!item.archived">
          mdi-cancel
        </v-icon>
      </template>

      <template v-slot:item.validated="{ item }">
        <v-icon class="mx-1" color="green" v-if="item.validated">
          mdi-check
        </v-icon>

        <v-icon class="mx-1" color="orange lighten-1" v-else-if="item.staged">
          mdi-lan-pending
        </v-icon>

        <v-icon class="mx-1" color="gray lighten-5" v-else>mdi-cancel</v-icon>
      </template>

      <template v-slot:item.staged="{ item }">
        <v-icon class="mx-1" color="green" v-if="item.staged">mdi-check</v-icon>
        <v-icon class="mx-1" color="gray lighten-5" v-if="!item.staged">mdi-cancel</v-icon>
      </template>

      <template v-slot:item.converted="{ item }">
        <v-icon class="mx-1" color="green" v-if="item.converted">mdi-check</v-icon>
        <v-icon class="mx-1" color="gray lighten-5" v-if="!item.converted">mdi-cancel</v-icon>
      </template>

      <template v-slot:item.errored="{ item }">
        <v-icon class="mx-1" color="red" v-if="item.errored">mdi-alert</v-icon>
        <v-icon class="mx-1" color="green lighten-5" v-if="!item.errored">mdi-cancel</v-icon>
      </template>

      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | moment('from') }}
      </template>

      <template v-slot:item.size="{ item }">
        <v-layout justify-end>
          <file-size :bytes="item.size"></file-size>
        </v-layout>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FileSize from '@/components/FileSize'
import ItemsCount from '@/components/ItemsCount'
// import RunDetails from '@/views/RunDetails'
import debounce from 'debounce'

export default {
  name: 'Runs',
  components: { FileSize, ItemsCount },

  // FYI: layouts are defined in router/index.js
  // layout: 'datatables',

  computed: {
    datasets_filtered() {
      if (!this.datasets.length) return []

      return this.datasets.map((d) => {
        // check if d already has a takenAt property
        if (d.takenAt) return d
        else {
          let yy = '20' + d.name.substr(0, 2)
          let mm = d.name.substr(2, 2)
          let dd = d.name.substr(4, 2)
          d.takenAt = `${yy}-${mm}-${dd}`
          return d
        }
      })
    },
  },
  data() {
    return {
      // sortDesc: true,
      // sortBy: 'takenAt',
      previousSortBy: '',
      expanded: [],
      dialog: false,
      deleteDialog: false,
      samplesheet: '',
      active_item: {
        name: '',
        createdAt: '',
        files: 0,
      },
      tab: '',

      headers: [
        { value: 'taken', text: '' },
        { value: 'name', text: 'Sequencing Run Name' },
        { value: 'takenAt', text: 'Date' },
        // {value: 'user', text: 'Submitted By'},
        // {value: 'fqdn', text: 'FQDN'},
        { value: 'archived', text: 'Archived' },
        // { value: 'staged', text: 'Staged' },
        { value: 'validated', text: 'Validated' },
        // { value: 'converted', text: 'Converted' },
        // { value: 'errored', text: 'Errored' },
        // {value: 'createdAt', text: 'Registered'},
        { value: 'updatedAt', text: 'Last Updated' },
        { value: 'cbcls', text: 'Data Files' },
        { value: 'size', text: 'Size' },
        // { value: 'actions', text: 'Actions' },
        // { value: 'data-table-expand', text: '' },
      ],
      datasets: [],
      selected: [],
      search: '',
      total: -1,
      page: 1,
      pages: -1,
      limit: 20,
      loading: false,
      options: {},
    }
  },
  mounted() {
    this.getDatasets()
  },
  methods: {
    clicked(value) {
      this.expanded.push(value)
    },
    getDatasets: debounce(function () {
      this.loading = true
      this.datasets = []
      this.$http
        .get(`${this.$config.api}/datasets/search`, {
          params: {
            page: this.page,
            limit: this.limit,
            filter: this.search,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] ? -1 : 1,
          },
        })
        .then((response) => {
          this.datasets = response.data.docs
          this.total = parseInt(response.data.total, 10)
          this.page = response.data.page
          this.pages = response.data.pages
          // console.log(response.data)
        })
        .finally(() => {
          this.loading = false
        })
    }, 250),
    handleSearch() {
      // if we're starting a search, make sure the page gets reset to 1
      // otherwise results may not appear
      this.page = 1
      this.options.sortBy = ['takenAt']
      this.options.sortDesc = [true]
      this.getDatasets()
    },
    handleClick(item) {
      // this.$router.push({ name: 'RunDetails', params: { name: item.name } })
      let routeData = this.$router.resolve({
        name: 'RunDetails',
        params: { name: item.name },
      })
      console.log('opening link', routeData.href)
      window.open(routeData.href, '_blank')
    },
    updateSummary(items) {
      this.selected = items
    },
    handleUpdateOptions() {
      // sort options changed, display results from page 1
      this.page = 1
      this.getDatasets()
    },
  },
}
</script>
